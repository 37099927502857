import PAGE from './page'
import CART from './cart'
import ACCOUNT from './account'
import BLOG from './blog'
import PRODUCT from './product'
import NAVIGATION_TREE from './navigationtrees'
import CATEGORIES from './categories'
import ORDER from './orders'
import SHIPPING_RULES from './shipping-rules'
import VENDOR from './vendor'
import COLLECTION from './collection'
import COUPON from './coupons'
import SITES from './sites'
import FILE from './file'
import LOCATIONS from './locations'

export interface ITransport {
  get: any
  put: any
  post: any
  delete: any
}

export interface ITransport {
  get: any
  put: any
  post: any
  delete: any
}

export enum VISIBILITY {
  PUBLIC = 'Public',
  PRIVATE = 'Private',
}

export const CMS_API_CALLS = {
  PAGE,
  ACCOUNT,
  BLOG,
  FILE,
  PRODUCT,
  COUPON,
  SITES,
  NAVIGATION_TREE,
  CATEGORIES,
  ORDER,
  SHIPPING_RULES,
  VENDOR,
  CART,
  COLLECTION,
  LOCATIONS,
}
