// const env = process.env
// const PRODUCTION = process.env.NODE_ENV === 'production'
import getConfig from 'next/config'
import { getStoreCode } from '../components/Utils/getStoreCodeFromCookie'
const { publicRuntimeConfig } = getConfig()

// ---------------------------- Global configs ------------------------------------
export const IS_DEV: string = publicRuntimeConfig.IS_DEV || 'FALSE'
export const CACHE_ENDPOINT = publicRuntimeConfig.CACHE_ENDPOINT || ''
export const CMS_API = publicRuntimeConfig.CMS_API || 'http://localhost:1337'
export const CONFIG_ENV: string = publicRuntimeConfig.CONFIG_ENV || 'local'
export const VERSION: string = publicRuntimeConfig.VERSION || 'localhost'
export const SKIP_DYNAMIC_REDIRECTS: boolean = false
export const CONTENT_VERSION: string = publicRuntimeConfig.CONTENT_VERSION || 'STAGING'
export const CMS_API_LOGIN_ENDPOINT: string = '/auth/local'
export const CURRENT_NODE_ENV: string = publicRuntimeConfig.NODE_ENV || 'LOCAL'
export const OC_REGISTRY_ENDPOINT: string = process.env.OC_REGISTRY_ENDPOINT || 'http://localhost:3030'
export const CART_COUNT_COOKIE_NAME: string = 'cart-count'
export const SESSION_ID_COOKIE: string = 'sig-id'
export const IMAGE_PROXY_URL = publicRuntimeConfig.IMAGE_PROXY_URL || 'https://image-proxy.dev.mor.merce.io/'

// ---------------------------- Google maps ------------------------------------
export const GOOGLE_GEOCODING_ENDPOINT = 'https://maps.googleapis.com/maps/api/geocode/json'
export const GOOGLE_GEOCODING_US_ENDPOINT = `${GOOGLE_GEOCODING_ENDPOINT}?components=country:US`

export const GEOCODING_KEY: string = 'AIzaSyB_h9bBOlHQR88ImOmzdZJk3DuD94ngFZY'
export const GOOGLE_MAPS_KEY: string = 'AIzaSyAnLxzaSNUP5ruhiXEfXeZc4B-FVD5vXok'
export const GOOGLE_MAPS_SCRIPT = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&libraries=geometry,drawing,places&v=3.exp`
export const METER_TO_MILE_EQUIVALENCE = 0.000621371192
export enum DEFAULT_COORDINATES {
  latitude = 35.605984,
  longtitude = -115.549113,
}

//  ---------------------------- Mor business-specific rules ------------------------------------
export enum ASSOCIATE_ROLES {
  ASSOCIATE = 'Associate',
}
export const ALLOWED_ASSOCIATE_ROLES: string[] = [ASSOCIATE_ROLES.ASSOCIATE]

/**
 * originCode: WEB body param identifies the request as a Mor Website request.
 */
export const ORIGIN_CODE_WEB = 'WEB'

/**
 * Just a placeholder MOP value for preSaveOrder. This gets updated with a real value
 * after calling auth.net. Ex: "V" -> Visa
 */
export const DEFAULT_MOP = 'TBD'

// --- In stock strings related filter ---
export const inStockStores: string = 'inStockStores_uFilter:'
export const inStockStoresEncodedFilter: string = 'inStockStores_uFilter:%22In%20Stock%22'
export const inStockStoresFilter: string = 'inStockStores_uFilter:"In Stock"'
export const inStockStoresParsedFilter: string = '&filter=inStockStores_uFilter%3A%22In%20Stock%22'
export const inStockStoresAttribute: string = 'inStockStores=In Stock'
export const availabilityLabel: string = 'Availability'

export const VARIANTS_PAGE_SIZE: number = 100
export const maxProductsToLoad: number = 32

// ---------------------------- UNBXD ------------------------------------
export const UNBXD_SITE_ID = publicRuntimeConfig.UNBXD_SITE_ID || 'stage-morfurniture-com811551581000069'
export const UNBXD_AUTOSUGGEST_CSS =
  process.env.UNBXD_AUTOSUGGEST_CSS || 'https://sandbox.unbxd.io/uat-morfurniture-com811551581701012_autosuggest.css'
export const UNBXD_AUTOSUGGEST_JS =
  process.env.UNBXD_AUTOSUGGEST_JS || 'https://sandbox.unbxd.io/uat-morfurniture-com811551581701012_autosuggest.js'
export const autosuggestConfig: string = `var UnbxdSiteName = "${UNBXD_SITE_ID}"; var UnbxdApiKey = "${
  process.env.UNBXD_API_KEY
}"; var UnbxdGeoFilter = "storeAvailability_uFilter:${getStoreCode()}"; var localdomain;`
export const merchantVisibility: string = 'MERCH_VISIBILITY_uFilter:"CATALOG, SEARCH"'
export const storeAvailability: string = 'storeAvailability_uFilter:'

// --------------------------- GERS Service -------------------------------
export const GERS_SERVICE = publicRuntimeConfig.GERS_SERVICE || 'https://gers.dev.mor.merce.io'
export const GERS_X_API_KEY = 'x-api-key'
export const GERS_X_API_VALUE = '?lO[)}Kl2$k/=:7N'

// --------------------------- Analytics -------------------------------

// GTM
export const GTM_ID = publicRuntimeConfig.GTM_ID || 'GTM-KWKDQH'
export const GTM_AUTH = publicRuntimeConfig.GTM_AUTH || 'U02zl2kmYeS0skQCnS-iIw'
export const GTM_ENV = publicRuntimeConfig.GTM_ENV || 'env-253'

// --------------------------- Third parties -------------------------------

// BazaarVoice (Product Review)
export const BAZAAR_VOICE_HOST = 'https://apps.bazaarvoice.com'
export const BAZAAR_VOICE_SCRIPT =
  publicRuntimeConfig.BAZAAR_VOICE_SCRIPT ||
  'https://apps.bazaarvoice.com/deployments/mor/main_site/staging/en_US/bv.js'
export const BAZAAR_VOICE_CURALATE_SOCIAL_REEL_SCRIPT = `var CRL8_SITENAME = 'morfurniture-p985rg';!function(){var e=window.crl8=window.crl8||{},n=!1,i=[];e.ready=function(e){n?e():i.push(e)},e.pixel=e.pixel||function(){e.pixel.q.push(arguments)},e.pixel.q=e.pixel.q||[];var t=window.document,o=t.createElement("script"),c=e.debug||-1!==t.location.search.indexOf("crl8-debug=true")?"js":"min.js";o.async=!0,o.src=t.location.protocol+"//edge.curalate.com/sites/"+CRL8_SITENAME+"/site/latest/site."+c,o.onload=function(){n=!0,i.forEach(function(e){e()})};var r=t.getElementsByTagName("script")[0];r.parentNode.insertBefore(o,r.nextSibling)}();`

export const AB_TASTY_SCRIPT_URL =
  publicRuntimeConfig.AB_TASTY_SCRIPT_URL || 'https://try.abtasty.com/92770cd2313eaabb8a5c4a7fc936903b.js'

// Synchrony Finance
export const SYF_SCRIPT = publicRuntimeConfig.SYF_SCRIPT || 'https://ubuy.syf.com/digitalbuy/js/merchant_ff.js'

// Cylindo 360 viewer
export const CYLINDO_JS = publicRuntimeConfig.CYLINDO_JS || 'https://viewer.cylindo.com/v4/viewer.min.js'
export const CYLINDO_CSS = publicRuntimeConfig.CYLINDO_CSS || 'https://viewer.cylindo.com/v4/viewer.min.css'
export const CYLINDO_ACCOUNT_ID = 5057
export const CYLINDO_VIEWER_ID = 'cylindo-viewer'
export const CYLINDO_PRODUCT_API = `https://content.cylindo.com/api/v2/${CYLINDO_ACCOUNT_ID}/products`
export const CYLINDO_FRAME_PATH = '/frames/'
export const CYLINDO_IMAGE_SIZE = 'size=512'
export const CYLINDO_IMAGE_EXT = '.jpg'

// Podium chat
export const PODIUM_CHAT_SCRIPT = 'https://connect.podium.com/widget.js#API_TOKEN=d5d0e357-023c-42c6-89ce-91b6dd055117'

export const curalateReel: string = `var CRL8_SITENAME = 'morfurniture-p985rg'; !function(){var e=window.crl8=window.crl8||{},n=!1,i=[];e.ready=function(e){n?e():i.push(e)},e.pixel=e.pixel||function(){e.pixel.q.push(arguments)},e.pixel.q=e.pixel.q||[];var t=window.document,o=t.createElement("script"),c=e.debug||-1!==t.location.search.indexOf("crl8-debug=true")?"js":"min.js";o.src=t.location.protocol+"//cdn.curalate.com/sites/"+CRL8_SITENAME+"/site/latest/site."+c,o.onload=function(){n=!0,i.forEach(function(e){e()})};var r=t.getElementsByTagName("script")[0];r.parentNode.insertBefore(o,r.nextSibling)}();`
export const PINTEREST_VALIDATION_KEY: string = 'a6de1ef2d290c7497a4138df58de5a10'

// --------------------------- Helpers -------------------------------
export const SPACE_REGEX = /\s/g
export const HYPHEN_REGEX = /-/g
export const PHONE_REGEX = /^\(?([0-9]{3})\)?\s([0-9]{3})[\s\-]?([0-9]{4})$/
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// --------------------------- Placeholders --------------------------
export const SEARCH_INPUT_LABEL = 'What can we help you find?'

// --------------------------- KLAVIYO --------------------------
export const KLAVIYO_PUBLIC_KEY = process.env.KLAVIYO_PUBLIC_KEY || 'UCUT5L'
export const KLAVIYO_URL: string = 'https://a.klaviyo.com/api'

//DATADOG LOGGING
export const DATADOG_API_KEY = 'b555dd9942ac1e98c2785770759e9d40'
export const DATADOG_APPLICATION_NAME = 'mor-web-pdp'
export const DATADOG_HOST = 'https://http-intake.logs.datadoghq.com'
export const DATADOG_ENABLE_LOGS = true

export const MOR_PHONE_NUMBER = '866-466-7435'
