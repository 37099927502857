import { IStoreLocation } from '../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Locations/interfaces'
import { IStoreCodes } from '../Tenant/Services/GERS/Pricing/storelocator'

// put all exported interfaces for this level here
export interface StoreItem {
  name: string
  streetAddress: string
  city: string
  state: string
  zip: string
  phone: string
}

export interface IStore {
  address: string
  city: string
  latitude: any
  longtitude: any
  name: string
  phone: string
  rewrite_request_path: string
  state: string
  storelocator_id: string
  zipcode: string
}

export interface IMorStoreLocation extends IStoreLocation {
  distance?: number
}
export interface IStoreLookupTable {
  id: string
  distance: number
}

export interface Coordinates {
  lat: number
  lng: number
}

export enum ILocationCookieType {
  GEOLOCATION = 'GEOLOCATION',
  STORE_CODES = 'STORE_CODES',
  LOOKUP_TABLE = 'LOOKUP_TABLE',
  ZONE_CODE = 'ZONE_CODE',
}

export enum IDynamicOptionsCookie {
  DYNAMIC_OPTIONS = 'DYNAMIC_OPTIONS',
  IN_STOCK_FILTER = 'IN_STOCK_FILTER',
}

export const DEFAULT_STORE_CODES: IStoreCodes = { storeCode: 'XX', warehouseCode: 'WX' }
