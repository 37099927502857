import { AxiosInstance, AxiosResponse } from 'axios'
import getConfig from 'next/config'
import stripLeadingSlash from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/stripLeadingSlash'
import getUnbxdInterceptor from './unbxdInterceptor'
const { publicRuntimeConfig } = getConfig()

/**
 * This only exist on the server side
 */
const UNBXD_API_KEY = publicRuntimeConfig.UNBXD_API_KEY
const UNBXD_SITE_ID = publicRuntimeConfig.UNBXD_SITE_ID

// -------------------------------------------------------- CATEGORIES / BROWSE ------------------------------------------------------------

/**
 * Browse
 * @param queryString
 */
export const getUnbxdCatalog = async (queryString: string): Promise<AxiosResponse> => {
  const UNBXD_API_BASE = `https://search.unbxd.io/${UNBXD_API_KEY}/${UNBXD_SITE_ID}/category`

  return getUnbxd(UNBXD_API_BASE, queryString, true)
}

// -------------------------------------------------------- SEARCH ------------------------------------------------------------

/**
 * Search
 * @param queryString
 */

export const getUnbxdSearch = async (queryString: string): Promise<AxiosResponse> => {
  const UNBXD_API_BASE = `https://search.unbxd.io/${UNBXD_API_KEY}/${UNBXD_SITE_ID}/search`

  return getUnbxd(UNBXD_API_BASE, queryString, false)
}

// -------------------------------------------------------- PRODUCT ------------------------------------------------------------

/**
 * Product
 * @param queryString
 */

export const getUnbxdProduct = async (queryString: string): Promise<AxiosResponse> => {
  const UNBXD_API_BASE = `https://search.unbxd.io/sites/${UNBXD_SITE_ID}/products`

  return getUnbxd(UNBXD_API_BASE, queryString, true)
}

// ----------------------------------------------------- RECOMMENDATIONS ------------------------------------------------------------

/**
 * Product Recs
 * @param queryString
 */

export const getUnbxdProductRecommendations = async (queryString: string): Promise<AxiosResponse> => {
  const UNBXD_API_BASE = `https://recommendations.unbxd.io/v2.0/${UNBXD_API_KEY}/${UNBXD_SITE_ID}/items`

  return getUnbxd(UNBXD_API_BASE, queryString, true)
}

// ----------------------------------------------------- AUTOSUGGEST ------------------------------------------------------------

export const getUnbxdSearchSuggestions = async (queryString: string): Promise<AxiosResponse> => {
  const UNBXD_API_BASE = `https://search.unbxd.io/sites/${UNBXD_SITE_ID}/autosuggest`

  return getUnbxd(UNBXD_API_BASE, queryString, true)
}

export const getUnbxdAutosuggestTopResults = async (query: string): Promise<AxiosResponse> => {
  const queryString = `${query}&version=V2&uid=uid-1715754392675-63263&inFields.count=0&topQueries.count=3&keywordSuggestions.count=8&promotedSuggestion.count=2&popularProducts.count=6&popularProducts.fields=title,images,productUrl,price_00,uniqueId,autosuggest,doctype&sourceFields=&indent=off&popularProducts.filter=-MERCH_VISIBILITY:%22NOT%20VISIBLE%20INDIVIDUALLY%22&_=1715755944268`
  const UNBXD_API_BASE = `https://search.unbxd.io/${UNBXD_API_KEY}/${UNBXD_SITE_ID}/autosuggest?q=`

  return getUnbxd(UNBXD_API_BASE, queryString, true)
}

const getUnbxd = async (url: string, queryString: string, authorizationHeader: boolean): Promise<AxiosResponse> => {
  /**
   * Will proxy through our cache if one is present
   */
  const safeQueryString: string = stripLeadingSlash(queryString)

  try {
    const unbxdAxiosInterceptor: AxiosInstance = getUnbxdInterceptor()

    if (authorizationHeader) {
      return await unbxdAxiosInterceptor.get(`${url}${safeQueryString}`, {
        headers: {
          Authorization: UNBXD_API_KEY,
        },
      })
    }

    return unbxdAxiosInterceptor.get(`${url}${safeQueryString}`)
  } catch (e) {
    console.log(`Error for following request url: ${url}${safeQueryString}.`)
    throw new Error(`Error for following request url: ${url}${safeQueryString}.`)
  }
}
