import * as React from 'react'

export interface ILink {
  children: any
  href: string
}

const Link = (props: ILink) => {
  return <a href={props.href}>{props.children}</a>
}

export default Link
