import {
  IApiCartGenericRequestParameters,
  IApiGetCartResponse,
  IApiCartItemRequestParameters,
  IApiQuantityCartRequestParameters,
  IApiCartAddCouponRequestParameters,
} from '../../../../Cart'
import { ITransport } from '.'

const ENDPOINTS = {
  GET_ITEMS: 'carts/getItems',
  ADD_ITEM: 'carts/addItem',
  GET_ITEM_COUNT: 'carts/getItemCount',
  REMOVE_ITEM: 'carts/removeItem',
  APPLY_COUPON: 'carts/applyCoupon',
  DELETE_ITEM: 'carts/deleteItem',
  MERGE: 'carts/merge',
  COPY: 'carts/copy',
  UPDATE_QUANTITY: 'carts/updateItemQuantity',
  CLEAR_CART: 'carts/clear',
  DELETE_ITEMS: 'carts/clearSelection',
}

// ----------------------------------------------------- GET ALL CART -----------------------------------------------------------------------
const getItems = async (
  transport: ITransport,
  params: IApiCartGenericRequestParameters,
): Promise<IApiGetCartResponse> => {
  try {
    const identity: any = {
      ...params.identity,
    }
    delete params.identity
    const endpoint: string = `/${ENDPOINTS.GET_ITEMS}`
    const response = await transport.post(endpoint, { ...params, ...identity })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}
const getItemCount = async (
  transport: ITransport,
  params: IApiCartGenericRequestParameters,
): Promise<IApiGetCartResponse> => {
  try {
    const identity: any = {
      ...params.identity,
    }
    delete params.identity
    const endpoint: string = `/${ENDPOINTS.GET_ITEM_COUNT}`
    const response = await transport.post(endpoint, { ...params, ...identity })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}
// ----------------------------------------------------- GET ALL CART -----------------------------------------------------------------------
const mergeCart = async (
  transport: ITransport,
  params: IApiCartGenericRequestParameters,
): Promise<IApiGetCartResponse> => {
  try {
    const identity: any = {
      ...params.identity,
    }
    delete params.identity
    const endpoint: string = `/${ENDPOINTS.MERGE}`
    const response = await transport.post(endpoint, { ...params, ...identity })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const copyCart = async (transport: ITransport, params: { [x: string]: string }): Promise<IApiGetCartResponse> => {
  try {
    const endpoint: string = `/${ENDPOINTS.COPY}`
    const response = await transport.post(endpoint, params)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}
// ----------------------------------------------------- Add Item -----------------------------------------------------------------------
const addItemToCart = async (
  transport: ITransport,
  params: IApiCartItemRequestParameters,
): Promise<IApiGetCartResponse> => {
  try {
    const identity: any = {
      ...params.identity,
    }
    delete params.identity
    const endpoint: string = `/${ENDPOINTS.ADD_ITEM}`
    const response = await transport.post(endpoint, { ...params, ...identity })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}
// ----------------------------------------------------- apply coupon -----------------------------------------------------------------------
const applyCouponToCart = async (
  transport: ITransport,
  params: IApiCartAddCouponRequestParameters,
): Promise<IApiGetCartResponse> => {
  try {
    const identity: any = {
      ...params.identity,
    }
    delete params.identity
    const endpoint: string = `/${ENDPOINTS.APPLY_COUPON}`
    const response = await transport.post(endpoint, { ...params, ...identity })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}
// ----------------------------------------------------- Add Item -----------------------------------------------------------------------
const removeItemFromCart = async (
  transport: ITransport,
  params: IApiCartItemRequestParameters,
): Promise<IApiGetCartResponse> => {
  try {
    const identity: any = {
      ...params.identity,
    }
    delete params.identity
    const endpoint: string = `/${ENDPOINTS.REMOVE_ITEM}`
    const response = await transport.post(endpoint, { ...params, ...identity })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}
// ----------------------------------------------------- Add Item -----------------------------------------------------------------------
const deleteItemFromCart = async (
  transport: ITransport,
  params: IApiCartItemRequestParameters,
): Promise<IApiGetCartResponse> => {
  try {
    const identity: any = {
      ...params.identity,
    }
    delete params.identity
    const endpoint: string = `/${ENDPOINTS.DELETE_ITEM}`
    const response = await transport.post(endpoint, { ...params, ...identity })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

// ----------------------------------------------------- Add Item -----------------------------------------------------------------------
const clearSelection = async (
  transport: ITransport,
  params: IApiCartGenericRequestParameters,
  productIDs: any[],
): Promise<IApiGetCartResponse> => {
  try {
    const identity: any = {
      ...params.identity,
    }
    delete params.identity
    const endpoint: string = `/${ENDPOINTS.DELETE_ITEMS}`
    const response = await transport.post(endpoint, { ...params, productIDs, ...identity })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}
// ----------------------------------------------------- Add Item -----------------------------------------------------------------------
const updateItemQuantity = async (
  transport: ITransport,
  params: IApiQuantityCartRequestParameters,
): Promise<IApiGetCartResponse> => {
  try {
    const identity: any = {
      ...params.identity,
    }
    delete params.identity
    const endpoint: string = `/${ENDPOINTS.UPDATE_QUANTITY}`
    const response = await transport.post(endpoint, { ...params, ...identity })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}
// ----------------------------------------------------- Add Item -----------------------------------------------------------------------
const clearCart = async (
  transport: ITransport,
  params: IApiCartGenericRequestParameters,
): Promise<IApiGetCartResponse> => {
  try {
    const identity: any = {
      ...params.identity,
    }
    delete params.identity
    const endpoint: string = `/${ENDPOINTS.CLEAR_CART}`
    const response = await transport.post(endpoint, { ...params, ...identity })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  getItems,
  applyCouponToCart,
  mergeCart,
  copyCart,
  getItemCount,
  updateItemQuantity,
  deleteItemFromCart,
  removeItemFromCart,
  clearCart,
  addItemToCart,
  clearSelection,
}
