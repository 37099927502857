import * as React from 'react'
import { MERCE_CLASS_NAMES } from '../interfaces'
import css from './PriceDisplay.scss'
import { IProduct } from '.'

export interface IPriceDisplayProps {
  product: IProduct
  usePriceDivider?: boolean
}

interface IPriceDisplay {
  title: string
  isCrossedOut?: boolean
  isSale?: boolean
  price: number | undefined
  priceUnit: string | undefined
  priceUnitDivider?: boolean
  className?: string
}

interface IMicroPriceDisplay {
  price: number | undefined
  unit: string | undefined
  priceUnitDivider?: boolean
}

const toFixedDec = (num: number | undefined): string => {
  if (num) {
    return `$${num.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}`
  }
  return 'N/A'
}

const priceUnitDisplay = (priceUnit?: string, priceUnitDivider?: boolean) => {
  if (priceUnitDivider && priceUnit) {
    return `/${priceUnit}`
  }
  return priceUnit || ''
}

const PriceDisplayElement = (props: IPriceDisplay) => {
  const { isCrossedOut, price, title, isSale, priceUnit, priceUnitDivider, className } = props
  const priceClassName = className ? className : ''
  if (isCrossedOut) {
    return (
      <div className={`${css.grey} ${priceClassName}`}>
        {title}:{' '}
        <span className={css.crossedOut}>
          {toFixedDec(price)} {priceUnitDisplay(priceUnit, priceUnitDivider)}
        </span>
      </div>
    )
  }
  if (isSale) {
    return (
      <div className={`${css.salePrice} ${priceClassName}`}>
        {title}:{' '}
        <span className={css.priceFont}>
          {toFixedDec(price)} {priceUnitDisplay(priceUnit, priceUnitDivider)}
        </span>
      </div>
    )
  }
  return (
    <div className={`${css.black} ${priceClassName}`}>
      {title}:{' '}
      <span className={css.priceFont}>
        {toFixedDec(price)} {priceUnitDisplay(priceUnit, priceUnitDivider)}
      </span>
    </div>
  )
}

const DisplayMicroPrice = (props: IMicroPriceDisplay) => {
  const { unit, price, priceUnitDivider } = props
  if (unit && price) {
    return (
      <div className={css.black}>
        {toFixedDec(price)} {priceUnitDisplay(unit, priceUnitDivider)}
      </div>
    )
  }
  return <div />
}

export default class PriceDisplay extends React.Component<IPriceDisplayProps> {
  public renderCore = () => {
    const { mapEnforced, price, ourPrice, map, salePrice, priceUnit, microPrice, microPriceUnit } = this.props.product
    const { usePriceDivider } = this.props
    // tmp hack since the prices are being returned from server as strings but typescript has it typed as a number
    // changing it to string breaks everything currently so we force it here
    const numPrice: number = parseFloat(price as any)
    const numOurPrice: number = parseFloat(ourPrice as any)
    const numMap: number = parseFloat(map as any)
    const numSalePrice: number = parseFloat(salePrice as any)
    const numMicroPrice: number = parseFloat(microPrice as any)
    const isMapEnforced: boolean = (mapEnforced as any) === 'true'
    if (isMapEnforced && numPrice && numMap < numPrice) {
      return (
        <div>
          <PriceDisplayElement
            price={numPrice}
            title="Regular Price"
            isCrossedOut={true}
            priceUnit={priceUnit}
            className={MERCE_CLASS_NAMES.PDP_PRODUCT_REGULAR_PRICE}
          />
          <PriceDisplayElement
            price={numMap}
            title="Our Price"
            priceUnit={priceUnit}
            priceUnitDivider={usePriceDivider}
            className={MERCE_CLASS_NAMES.PDP_PRODUCT_OUR_PRICE}
          />
          <DisplayMicroPrice price={numMicroPrice} unit={microPriceUnit} priceUnitDivider={usePriceDivider} />
        </div>
      )
    }
    if (isMapEnforced) {
      return (
        <div>
          <PriceDisplayElement
            price={numMap}
            title="Our Price"
            priceUnit={priceUnit}
            priceUnitDivider={usePriceDivider}
            className={MERCE_CLASS_NAMES.PDP_PRODUCT_OUR_PRICE}
          />
          <DisplayMicroPrice price={numMicroPrice} unit={microPriceUnit} priceUnitDivider={usePriceDivider} />
        </div>
      )
    }

    if (
      numSalePrice &&
      numSalePrice > 0 &&
      numPrice &&
      numSalePrice < numPrice &&
      numSalePrice < numOurPrice &&
      numPrice > numOurPrice
    ) {
      return (
        <div>
          <PriceDisplayElement
            price={numPrice}
            title="Regular Price"
            isCrossedOut={true}
            priceUnit={priceUnit}
            className={MERCE_CLASS_NAMES.PDP_PRODUCT_REGULAR_PRICE}
          />
          <PriceDisplayElement
            price={numOurPrice}
            title="Our Price"
            isCrossedOut={true}
            priceUnit={priceUnit}
            className={MERCE_CLASS_NAMES.PDP_PRODUCT_OUR_PRICE}
          />
          <PriceDisplayElement
            price={numSalePrice}
            title="Sale Price"
            isSale={true}
            priceUnit={priceUnit}
            priceUnitDivider={usePriceDivider}
            className={MERCE_CLASS_NAMES.PDP_PRODUCT_SALE_PRICE}
          />
          <DisplayMicroPrice price={numMicroPrice} unit={microPriceUnit} priceUnitDivider={usePriceDivider} />
        </div>
      )
    }
    if (numSalePrice && numSalePrice > 0 && numSalePrice < numOurPrice) {
      return (
        <div>
          <PriceDisplayElement
            price={numOurPrice}
            title="Our Price"
            isCrossedOut={true}
            priceUnit={priceUnit}
            className={MERCE_CLASS_NAMES.PDP_PRODUCT_OUR_PRICE}
          />
          <PriceDisplayElement
            price={numSalePrice}
            title="Sale Price"
            isSale={true}
            priceUnit={priceUnit}
            priceUnitDivider={usePriceDivider}
            className={MERCE_CLASS_NAMES.PDP_PRODUCT_SALE_PRICE}
          />
          <DisplayMicroPrice price={numMicroPrice} unit={microPriceUnit} priceUnitDivider={usePriceDivider} />
        </div>
      )
    }
    if (numPrice && numPrice > numOurPrice) {
      return (
        <div>
          <PriceDisplayElement
            price={numPrice}
            title="Regular Price"
            isCrossedOut={true}
            priceUnit={priceUnit}
            className={MERCE_CLASS_NAMES.PDP_PRODUCT_REGULAR_PRICE}
          />
          <PriceDisplayElement
            price={numOurPrice}
            title="Our Price"
            priceUnit={priceUnit}
            priceUnitDivider={usePriceDivider}
            className={MERCE_CLASS_NAMES.PDP_PRODUCT_OUR_PRICE}
          />
          <DisplayMicroPrice price={numMicroPrice} unit={microPriceUnit} priceUnitDivider={usePriceDivider} />
        </div>
      )
    }

    if (!isMapEnforced && numOurPrice == null) {
      return (
        <div>
          <PriceDisplayElement
            price={numPrice}
            title="Regular Price"
            isCrossedOut={true}
            priceUnit={priceUnit}
            priceUnitDivider={usePriceDivider}
            className={MERCE_CLASS_NAMES.PDP_PRODUCT_REGULAR_PRICE}
          />
          <DisplayMicroPrice price={numMicroPrice} unit={microPriceUnit} priceUnitDivider={usePriceDivider} />
        </div>
      )
    }

    return (
      <div>
        <PriceDisplayElement
          price={numOurPrice || numPrice}
          title="Our Price"
          priceUnit={priceUnit}
          priceUnitDivider={usePriceDivider}
          className={MERCE_CLASS_NAMES.PDP_PRODUCT_OUR_PRICE}
        />
        <DisplayMicroPrice price={numMicroPrice} unit={microPriceUnit} priceUnitDivider={usePriceDivider} />
      </div>
    )
  }
  public render() {
    return <div className={css.core}>{this.renderCore()}</div>
  }
}
