enum CONTEXT {
  STUDIO = 'st',
  FRONTEND = 'fnd',
}

export enum GROUP {
  CONTENT = 'ct',
  THEME = 'th',
  BLOG = 'bl',
  MISC = 'misc',
  PIM = 'pim',
  SITE = 'site',
}

const CACHE_CONTSTANT = {
  NO_CACHE: 'no-cache',
  STUDIO: {
    ROOT: `${CONTEXT.STUDIO}`,
    MISC: {
      ROOT: `${CONTEXT.STUDIO}-${GROUP.MISC}`,
      REDIRECTS: `${CONTEXT.STUDIO}-${GROUP.MISC}-redirects`,
      THEME: `${CONTEXT.STUDIO}-${GROUP.MISC}-theme`,
      LOCATIONS: `${CONTEXT.STUDIO}-${GROUP.MISC}-locations`,
      PUBLIC_CONFIGURATIONS: `${CONTEXT.STUDIO}-${GROUP.MISC}-publicconfigurations`,
      CART_LAYOUTS: `${CONTEXT.STUDIO}-${GROUP.MISC}-cartlayouts`,
      PDP_LAYOUTS: `${CONTEXT.STUDIO}-${GROUP.MISC}-pdplayouts`,
      SHIPPING_RULES: `${CONTEXT.STUDIO}-${GROUP.MISC}-cartshippingrules`,
      DYNAMIC_CONTENT_TYPES: `${CONTEXT.STUDIO}-${GROUP.MISC}-dynamiccontenttypes`,
    },
    SURVEYS: {
      ROOT: `${CONTEXT.STUDIO}-surveys`,
    },
    PLUGINS: {
      ROOT: `${CONTEXT.STUDIO}-plugins`,
    },
    ORDER: {
      ROOT: `${CONTEXT.STUDIO}-om`,
    },
    BLOG: {
      ROOT: `${CONTEXT.STUDIO}-${GROUP.BLOG}`,
      NAVIGATION: `${CONTEXT.STUDIO}-${GROUP.BLOG}-navigation`,
      CATEGORY: `${CONTEXT.STUDIO}-${GROUP.BLOG}-category`,
      ARTICLE: `${CONTEXT.STUDIO}-${GROUP.BLOG}-article`,
    },
    CONTENT: {
      ROOT: `${CONTEXT.STUDIO}-${GROUP.CONTENT}`,
      PAGE: `${CONTEXT.STUDIO}-${GROUP.CONTENT}-page`,
      CONTENT_BLOCK: `${CONTEXT.STUDIO}-${GROUP.CONTENT}-contentblocks`,
      NAVIGATION_TREE: `${CONTEXT.STUDIO}-${GROUP.CONTENT}-nav-tree`,
      LINK_HIERARCHIES: `${CONTEXT.STUDIO}-${GROUP.CONTENT}-linkhierarchies`,
    },
    PIM: {
      ROOT: `${CONTEXT.STUDIO}-${GROUP.PIM}`,
      PRODUCTS: `${CONTEXT.STUDIO}-${GROUP.PIM}-products`,
    },
    SITE: {
      ROOT: `${CONTEXT.STUDIO}-${GROUP.SITE}`,
    },
  },
  FRONTEND: {
    ROOT: `${CONTEXT.FRONTEND}`,
    MISC: {
      ROOT: `${CONTEXT.FRONTEND}-${GROUP.MISC}`,
      HEADER_FOOTER: `${CONTEXT.FRONTEND}-${GROUP.MISC}-header-footer`,
      HEADER_FOOTER_CONTENT_BLOCK: `${CONTEXT.FRONTEND}-${GROUP.MISC}-header-footer-content-block`,
      SUB_CATEGORIES_FOR: `${CONTEXT.FRONTEND}-${GROUP.MISC}-sub-categories-for`,
      REDIRECTS: `${CONTEXT.FRONTEND}-${GROUP.MISC}-redirects`,
      THEME: `${CONTEXT.FRONTEND}-${GROUP.CONTENT}-theme`,
      LOCATIONS: `${CONTEXT.FRONTEND}-${GROUP.MISC}-locations`,
      CART_LAYOUTS: `${CONTEXT.FRONTEND}-${GROUP.MISC}-cartlayouts`,
      PDP_LAYOUTS: `${CONTEXT.FRONTEND}-${GROUP.MISC}-pdplayouts`,
      PUBLIC_CONFIGURATIONS: `${CONTEXT.FRONTEND}-${GROUP.MISC}-publicconfigurations`,
      SHIPPING_RULES: `${CONTEXT.FRONTEND}-${GROUP.MISC}-cartshippingrules`,
      DYNAMIC_CONTENT_TYPES: `${CONTEXT.FRONTEND}-${GROUP.MISC}-dynamiccontenttypes`,
      PROMOS: `${CONTEXT.FRONTEND}-${GROUP.MISC}-promos`,
    },
    SURVEYS: {
      ROOT: `${CONTEXT.FRONTEND}-surveys`,
    },
    BLOG: {
      ROOT: `${CONTEXT.FRONTEND}-${GROUP.BLOG}`,
      NAVIGATION: `${CONTEXT.FRONTEND}-${GROUP.BLOG}-navigation`,
      CATEGORY: `${CONTEXT.FRONTEND}-${GROUP.BLOG}-category`,
      CATEGORY_POSTS: `${CONTEXT.FRONTEND}-${GROUP.BLOG}-category-posts`,
      ARTICLE: `${CONTEXT.FRONTEND}-${GROUP.BLOG}-article`,
      TAGS: `${CONTEXT.FRONTEND}-${GROUP.BLOG}-tags`,
      SUMMARY: `${CONTEXT.FRONTEND}-${GROUP.BLOG}-summary`,
    },
    CONTENT: {
      ROOT: `${CONTEXT.FRONTEND}-${GROUP.CONTENT}`,
      PAGE: `${CONTEXT.FRONTEND}-${GROUP.CONTENT}-page`,
      CONTENT_BLOCK: `${CONTEXT.FRONTEND}-${GROUP.CONTENT}-contentblocks`,
      NAVIGATION_TREE: `${CONTEXT.FRONTEND}-${GROUP.CONTENT}-nav-tree`,
      LINK_HIERARCHIES: `${CONTEXT.FRONTEND}-${GROUP.CONTENT}-linkhierarchies`,
    },
    PIM: {
      ROOT: `${CONTEXT.FRONTEND}-${GROUP.PIM}`,
      CATEGORY: `${CONTEXT.FRONTEND}-${GROUP.PIM}-category`,
      PRODUCTS: `${CONTEXT.FRONTEND}-${GROUP.PIM}-products`,
      COLLECTIONS: `${CONTEXT.FRONTEND}-${GROUP.PIM}-collections`,
      METADATA: `${CONTEXT.FRONTEND}-${GROUP.PIM}-metadata`,
      CATEGORIES: `${CONTEXT.STUDIO}-${GROUP.PIM}-categories`,
      ATTRIBUTES: `${CONTEXT.STUDIO}-${GROUP.PIM}-attributes`,
      CATEGORY_ATTRIBUTES: `${CONTEXT.STUDIO}-${GROUP.PIM}-attributes-category`,
    },
    SITE: {
      ROOT: `${CONTEXT.FRONTEND}-${GROUP.SITE}`,
    },
  },
}

export default CACHE_CONTSTANT
