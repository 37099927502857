import * as React from 'react'
import css from './ProductBreadcrumbs.module.scss'
import Link from '../../Common/Link'
import { IProductBreadcrumbItem } from '../'

export interface IProps {
  links: IProductBreadcrumbItem[] | null
  linkWrapper?: any
}
export default class ProductBreadcrumbs extends React.Component<IProps, any> {
  public addSeparator = (index: number, len: number) => {
    const threshold = len - 1
    if (index >= threshold) {
      return <div />
    }
    return <span className={css.slash}>/</span>
  }
  public addTitle = (item: IProductBreadcrumbItem, index: number, len: number) => {
    const { title, url } = item
    const threshold = len - 1
    if (index >= threshold) {
      return <span>{title.toUpperCase()}</span>
    }
    if (this.props.linkWrapper) {
      return (
        <this.props.linkWrapper href={url} prefetch={false}>
          <a>{title.toUpperCase()}</a>
        </this.props.linkWrapper>
      )
    }
    return (
      <Link href={url}>
        {' '}
        prefetch={false}
        <a>{title.toUpperCase()}</a>
      </Link>
    )
  }
  public render() {
    const { links } = this.props
    if (!links) {
      return <div />
    }
    return (
      <div className={css.productTrails}>
        {links.map((item: IProductBreadcrumbItem, index: number) => {
          return (
            <span className={css.crumb} key={index}>
              {this.addTitle(item, index, links.length)}
              {this.addSeparator(index, links.length)}
            </span>
          )
        })}
      </div>
    )
  }
}
