import axios, { AxiosInstance } from 'axios'
export interface IInterceptorProps {
  baseUrl?: string
  cacheUrl?: string
}

const getUnbxdInterceptor = (options?: IInterceptorProps): AxiosInstance => {
  let targetBaseApiEndpoint: string = ''
  //overwrite if one is passed in
  if (options?.baseUrl !== undefined) {
    targetBaseApiEndpoint = options.baseUrl
  }

  // finally, we set our baseURL to our final endpoint
  const apiAxiosInstance = axios.create({
    baseURL: targetBaseApiEndpoint,
  })

  apiAxiosInstance.interceptors.response.use(
    config => config,
    error => {
      console.log('error getUnbxdInterceptor')
      // return Promise.reject(error)
      return
    },
  )

  return apiAxiosInstance
}

/**
 * For when we want to bake the base URL into a pre-defined axios instance and pass additional parameters to it later for runtime calling
 * @param overlayParams
 */
export const getUnbxdInterceptorPackage = (overlayParams?: IInterceptorProps) => {
  return (options?: IInterceptorProps) => {
    return getUnbxdInterceptor({ ...options, ...overlayParams })
  }
}

export default getUnbxdInterceptor
