import * as React from 'react'
import css from './Layout.module.scss'

export interface IAppProps {
  children?: any
  xs?: number
  sm?: number
  md?: number
  lg?: number
  oxs?: number
  osm?: number
  omd?: number
  olg?: number
  center?: boolean
  noPadding?: boolean
  textRight?: boolean
  textCenter?: boolean
  relative?: boolean
  className?: string
  key?: any
  style?: any
}

/**
 * Wrapper Class for keeping the use of Bootrap components consistent
 * @param xs number: omnimerse-column-xs-{number}
 * @param sm number: omnimerse-column-sm-{number}
 * @param md number: omnimerse-column-md-{number}
 * @param lg number: omnimerse-column-lg-{number}
 * @param center boolean: if you want to center all content in the column
 * @param noPadding boolean: remove all padding from column
 */
export default class Col extends React.Component<IAppProps, any> {
  public renderClasses = () => {
    let classes = []
    const { relative, xs, sm, md, lg, oxs, osm, omd, olg, center, noPadding, textCenter, textRight } = this.props
    if (xs) {
      classes.push(css[`omnimerse-column-xs-${xs}`])
    }
    if (sm) {
      classes.push(css[`omnimerse-column-sm-${sm}`])
    }
    if (md) {
      classes.push(css[`omnimerse-column-md-${md}`])
    }
    if (lg) {
      classes.push(css[`omnimerse-column-lg-${lg}`])
    }
    if (oxs) {
      classes.push(css[`omnimerse-column-xs-offset-${oxs}`])
    }
    if (osm) {
      classes.push(css[`omnimerse-column-sm-offset-${osm}`])
    }
    if (omd) {
      classes.push(css[`omnimerse-column-md-offset-${omd}`])
    }
    if (olg) {
      classes.push(css[`omnimerse-column-lg-offset-${olg}`])
    }
    // if no values were defined, let's default to this
    if (!classes.length) {
      classes.push(css['omnimerse-column-xs-12'])
    }
    // if we want to center our content in the middle
    if (center) {
      classes.push(css.colCentered)
    }
    // sometimes we don't want padding
    if (noPadding) {
      classes.push(css.noPadding)
    }
    if (textRight && textCenter === undefined) {
      classes.push(css.textRight)
    }
    if (textCenter) {
      classes.push(css.textCenter)
    }
    if (relative) {
      classes.push(css.relative)
    }

    // add default classes no matter what
    classes.push(css.marginTopOnSmall)
    if (this.props.className) {
      classes = [...classes, this.props.className.split(' ')]
    }
    return classes.join(' ')
  }
  public renderBody = () => {
    const { style, children } = this.props
    if (style) {
      return (
        <div className={`${this.renderClasses()} merce-column`} style={style}>
          {children}
        </div>
      )
    }
    return <div className={`${this.renderClasses()} merce-column`}>{children}</div>
  }
  public render() {
    return this.renderBody()
  }
}
