import { AxiosResponse } from 'axios'
import { IncomingMessage } from 'http'
import { NextPageContext } from 'next'
import { IBrowsePageProps } from '../../../pages/catalog'
import appendQueryString from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/appendQueryString'
import generateProductLink from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/generateProductLink'
import { isEmpty } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/lodash'
import { IUnbxdProduct } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import { IProduct } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import { ILocationCookieType } from '../../components/StoreLocator/interfaces'
import { ICatalogNavigationItem } from '../../components/Tenant/Layout/Catalog/Navigation/Navigation'
import { IStoreCodes } from '../../components/Tenant/Services/GERS/Pricing/storelocator'
import {
  IMorUnbxdProduct,
  adaptUnbxdFacetBrowse,
  addInStockStoreFilter,
  removeInStockStoreFacet,
  replaceCustomFacets,
} from '../../components/Utils/unbxdUtils'
import { inStockStoresEncodedFilter, merchantVisibility, storeAvailability } from '../../settings/variables'
import { getCookie } from '../cookie'
import { getUnbxdCatalog } from '../unbxd/unbxdApiCalls'
import {
  adaptUnbxdProductVariants,
  appendCommonQueryStrings,
  buildFilterGroupForMutiValueFacet,
  encodeFilters,
  IFacetFilter,
} from '../unbxd/unbxdHelpers'
import { ICategoryBreadcrumb } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS/categories'

export const parseRequestUrlAndPath = (
  ctx: NextPageContext,
  usePathnameForCanonicalUrl: boolean,
  isHomePage?: boolean,
) => {
  const { req, pathname } = ctx
  const host: string = req && req.headers.host ? req.headers.host : window.location.hostname
  const protocol: string = host.indexOf('localhost') > -1 ? 'http:' : 'https:'
  const baseUrl: string = `${protocol}//${host}`

  const canonicalUrl = usePathnameForCanonicalUrl
    ? `${protocol}//${host}${pathname}`
    : isHomePage
    ? `${protocol}//${host}`
    : `${protocol}//${host}${req?.url}`

  return {
    host,
    protocol,
    baseUrl,
    canonicalUrl,
    pathname,
  }
}

export const getClientIPAddress = (req: IncomingMessage | undefined) => {
  return req?.headers['x-real-ip']?.toString() || req?.connection.remoteAddress || ''
}

export const buildUnbxdRequest = async (
  url: string,
  returnedProps: IBrowsePageProps,
  query: any,
  matching: any,
  req: any,
) => {
  let inStock = false
  url = appendQueryString(url, { version: 'V2' })
  url = appendQueryString(url, { pagetype: 'boolean' })
  url = appendCommonQueryStrings(url, query?.start, query?.filter?.length > 0)

  if (!isEmpty(matching)) {
    if (matching?.sort) {
      url = appendQueryString(url, { sort: matching.sort })
    }
    if (matching.filter) {
      matching.filter = encodeFilters(matching.filter.map((a: IFacetFilter) => a.value))
      inStock = removeInStockStoreFacet(matching, inStock, inStockStoresEncodedFilter)
      replaceCustomFacets(matching)

      const filterGroup = buildFilterGroupForMutiValueFacet(matching.filter, [])

      for (const key in filterGroup) {
        url = appendQueryString(url, { filter: filterGroup[key] })
      }
    }
  }
  url = appendQueryString(url, { filter: merchantVisibility })

  const existingCookie: string | undefined = getCookie(ILocationCookieType.STORE_CODES, req)
  let codes: IStoreCodes | null = null
  if (existingCookie) {
    codes = JSON.parse(unescape(existingCookie)) as IStoreCodes
    url = appendQueryString(url, { filter: `${storeAvailability}${codes.storeCode}` })
    url = addInStockStoreFilter(url, inStock, codes.storeCode)
  }

  const targetResponse: AxiosResponse = await getUnbxdCatalog(url)
  const products: IUnbxdProduct[] = adaptUnbxdProductVariants(targetResponse.data)

  const facets: ICatalogNavigationItem[] = targetResponse.data.facets
    ? adaptUnbxdFacetBrowse(targetResponse.data.facets.text.list, products as IMorUnbxdProduct[])
    : []
  return {
    ...returnedProps,
    unbxdUrlDisplay: url,
    facets,
    products,
    results: targetResponse.data,
    requestId: targetResponse.headers['x-request-id'],
  }
}

export const getURLToProduct = (product: IProduct): string => {
  return generateProductLink(product, 'product')
}

export const getBreadcrumbString = (breadcrumbs: ICategoryBreadcrumb[]) => {
  const titles = breadcrumbs.map(breadcrumb => breadcrumb.title.toUpperCase())
  return titles.join(' > ')
}
