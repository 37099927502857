import { availabilityLabel, inStockStores, inStockStoresAttribute } from '../../settings/variables'
import { getCookie, removeCookie } from '../../utils/cookie'
import appendQueryString from '../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/appendQueryString'
import { each } from '../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/lodash'
import {
  IUnbxdFacet,
  IUnbxdFacetResults,
  IUnbxdFacetType,
} from '../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import { IProduct } from '../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import { IDynamicOptionsCookie } from '../StoreLocator/interfaces'
import { ICatalogNavigationItem } from '../Tenant/Layout/Catalog/Navigation/Navigation'

interface IUnbxdFacetValue {
  values: any[]
  facetName: string
  filterField: string
  displayName: string
  position?: number
}

export interface IMorUnbxdProduct extends IProduct {
  cylindoProductId?: string
  parentName?: string
  IMAGE360?: 'Y' | 'N'
  ACCENT_COLOR_STOCK_SKU?: string
  ACCENT_COLOR_STOCK_SKU2?: string
  ACCENT_COLOR_STOCK_SKU3?: string
  variants?: IVariant[]
  CYLINDO_MATERIAL_CD?: string
  relevantDocument?: 'parent' | 'variant'
  parentImage?: string
  catlevel1Name: string
  DELIVERY_QUALIFICATION?: 'FREEWHITEGLOVE' | 'FREESHIP'
  VENDOR_TYPE?: string
}

export interface IVariant {
  cylindoProductId?: string
  IMAGE360?: 'Y' | 'N'
  RELATED_ITM_CD_LIST?: string[]
  RELATED_TYPE?: IRelatedType
  CUSTOM_SKU?: 'Y' | 'N'
}

export enum IRelatedType {
  CUSTOMSKU = 'CUSTOMSKU',
  INVSKU = 'INVSKU',
}

const getFacets = (items: ICatalogNavigationItem[], products: IMorUnbxdProduct[]) => {
  const hasOnlineOnly = products.some(product => product.VENDOR_TYPE === 'Endless Aisle')
  const hasFreeDelivery = products.some(product => product.DELIVERY_QUALIFICATION === 'FREEWHITEGLOVE')
  const hasFreeShipping = products.some(product => product.DELIVERY_QUALIFICATION === 'FREESHIP')

  let facets: ICatalogNavigationItem[] = [
    {
      label: 'Availability',
      code: 'inStockStores',
      values: [{ value: 'In Stock', count: 0 }],
    },
  ]

  // Update values for 'Availability' if necessary
  if (hasOnlineOnly) {
    const availabilityFacetIndex = facets.findIndex(facet => facet.label === 'Availability')
    if (availabilityFacetIndex !== -1) {
      const availabilityFacet = facets[availabilityFacetIndex]
      const onlineOnlyValueIndex = availabilityFacet.values.findIndex(value => value.value === 'Online Only')
      if (onlineOnlyValueIndex !== -1) {
        availabilityFacet.values[onlineOnlyValueIndex].count = 0
      } else {
        availabilityFacet.values.push({ value: 'Online Only', count: 0 })
      }
      facets[availabilityFacetIndex] = availabilityFacet
    } else {
      // Add 'Online Only' value if 'Availability' facet doesn't exist
      facets.push({
        label: 'Availability',
        code: 'inStockStores',
        values: [{ value: 'Online Only', count: 0 }],
      })
    }
  }

  // Update values for 'Shipping & Delivery' if necessary
  const shippingDeliveryFacetIndex = facets.findIndex(facet => facet.label === 'Shipping & Delivery')
  if (shippingDeliveryFacetIndex !== -1) {
    const shippingDeliveryFacet = facets[shippingDeliveryFacetIndex]
    if (hasFreeDelivery) {
      const freeDeliveryValueIndex = shippingDeliveryFacet.values.findIndex(value => value.value === 'Free Delivery')
      if (freeDeliveryValueIndex !== -1) {
        shippingDeliveryFacet.values[freeDeliveryValueIndex].count = 0
      } else {
        shippingDeliveryFacet.values.push({ value: 'Free Delivery', count: 0 })
      }
    }
    if (hasFreeShipping) {
      const freeShippingValueIndex = shippingDeliveryFacet.values.findIndex(value => value.value === 'Free Shipping')
      if (freeShippingValueIndex !== -1) {
        shippingDeliveryFacet.values[freeShippingValueIndex].count = 0
      } else {
        shippingDeliveryFacet.values.push({ value: 'Free Shipping', count: 0 })
      }
    }
    facets[shippingDeliveryFacetIndex] = shippingDeliveryFacet
  } else {
    // Add 'Shipping & Delivery' facet if it doesn't exist
    if (hasFreeDelivery || hasFreeShipping) {
      const shippingDeliveryValues: { value: string; count: number }[] = []
      if (hasFreeDelivery) {
        shippingDeliveryValues.push({ value: 'Free Delivery', count: 0 })
      }
      if (hasFreeShipping) {
        shippingDeliveryValues.push({ value: 'Free Shipping', count: 0 })
      }
      facets.push({
        label: 'Shipping & Delivery',
        code: 'DELIVERY_QUALIFICATION',
        values: shippingDeliveryValues,
      })
    }
  }

  // Add remaining items
  facets = facets.concat(items)
  return facets
}

const adaptUnbxdFacetBrowse = (facets: IUnbxdFacetResults, products: IMorUnbxdProduct[]): ICatalogNavigationItem[] => {
  const items: ICatalogNavigationItem[] = []
  each(facets, (facetValue: IUnbxdFacetValue) => {
    const facetKey: string = facetValue.facetName
    const item: ICatalogNavigationItem = {
      label: facetValue.displayName,
      code: facetKey.split('_uFilter')[0],
      values: [],
    }
    for (let i = 0; i < facetValue.values.length; i += 2) {
      const val = facetValue.values[i]
      const count: any = facetValue.values[i + 1]
      item.values.push({
        value: val as any,
        count,
        code: facetKey.split('_uFilter')[0],
      })
    }
    if (item.values.length > 0) {
      items.push(item)
    }
  })

  return getFacets(items, products)
}

const adaptUnbxdFacet = (facets: IUnbxdFacetResults, products: IMorUnbxdProduct[]): ICatalogNavigationItem[] => {
  const items: ICatalogNavigationItem[] = []
  each(facets, (facetValue: IUnbxdFacet, facetKey: string) => {
    const item: ICatalogNavigationItem = {
      label: facetValue.displayName,
      code: facetKey.split('_uFilter')[0],
      values: [],
    }

    if (facetValue.type === IUnbxdFacetType.FIELDS) {
      for (let i = 0; i < facetValue.values.length; i += 2) {
        const val = facetValue.values[i]
        const count: any = facetValue.values[i + 1]
        item.values.push({
          value: val as any,
          count,
          code: facetKey.split('_uFilter')[0],
        })
      }
    }
    if (item.values.length > 0) {
      items.push(item)
    }
  })

  return getFacets(items, products)
}

/**
 * In Stock Store is added as a facet to filter products, but it does not work as an actual facet, is
 * a filter in the unbxd api call, so it needs to be removed from the facets logic.
 */
const removeInStockStoreFacet = (matching: any, inStock: boolean, inStockStoresFilter: string) => {
  const index = matching.filter.indexOf(inStockStoresFilter)
  if (index > -1) {
    matching.filter.splice(index, 1)
    inStock = true
  }
  return inStock
}

const replaceCustomFacets = (matching: any) => {
  const freeDeliveryFilter = 'DELIVERY_QUALIFICATION_uFilter:"FREEWHITEGLOVE"'
  const freeDeliveryFilterIndex = matching.filter.findIndex(
    (filter: string | string[]) =>
      filter.includes('DELIVERY_QUALIFICATION_uFilter:"Free Delivery"') ||
      filter.includes('DELIVERY_QUALIFICATION_uFilter:%22Free%20Delivery%22'),
  )

  if (freeDeliveryFilterIndex !== -1) {
    matching.filter[freeDeliveryFilterIndex] = freeDeliveryFilter
  }

  const freeShippingFilter = 'DELIVERY_QUALIFICATION_uFilter:"FREESHIP"'
  const freeShippingFilterIndex = matching.filter.findIndex(
    (filter: string | string[]) =>
      filter.includes('DELIVERY_QUALIFICATION_uFilter:"Free Shipping"') ||
      filter.includes('DELIVERY_QUALIFICATION_uFilter:%22Free%20Shipping%22'),
  )

  if (freeShippingFilterIndex !== -1) {
    matching.filter[freeShippingFilterIndex] = freeShippingFilter
  }

  const onlineOnlyFilter = 'VENDOR_TYPE_uFilter:"Endless Aisle"'
  const onlineOnlyFilterIndex = matching.filter.findIndex(
    (filter: string | string[]) =>
      filter.includes('inStockStores_uFilter:"Online Only"') ||
      filter.includes('inStockStores_uFilter:%22Online%20Only%22'),
  )

  if (onlineOnlyFilterIndex !== -1) {
    matching.filter[onlineOnlyFilterIndex] = onlineOnlyFilter
  }
}

/**
 * In Stock Store is added as a facet to filter products, but it does not work as an actual facet, is
 * a filter in the unbxd api call, so it needs to be added to the unbxd url.
 */
const addInStockStoreFilter = (url: string, inStock: boolean, storeCode: string) => {
  if (inStock) {
    url = appendQueryString(url, { filter: `${inStockStores}${storeCode}` })
  }
  return url
}

/**
 * If In Stock was checked on the search box, a cookie is saved and consumed by this method to act like a "click"
 * on the Availability: In Stock facet/filter on the initLoad of catalog and search pages.
 *
 * The cookie is deleted after showing the In Stock product results, to avoid showing In Stock products again in a page refresh and confuse the user.
 *
 * @param onAttributeFilterClick function to handle the click event on facets/filters used on catalog and search pages.
 */
const addInStockStoreFilterOnSearch = (onAttributeFilterClick: (targetQuery: string, label: string) => void) => {
  const inStockFilterCookie = getCookie(IDynamicOptionsCookie.IN_STOCK_FILTER)
  if (inStockFilterCookie) {
    const inStockFilterOnSearch = JSON.parse(inStockFilterCookie)
    const inStockFilterOnSearchValue = inStockFilterOnSearch.inStockFilter
    if (inStockFilterOnSearchValue) {
      onAttributeFilterClick(inStockStoresAttribute, availabilityLabel)
      removeCookie(IDynamicOptionsCookie.IN_STOCK_FILTER)
    }
  }
}

export {
  adaptUnbxdFacetBrowse,
  adaptUnbxdFacet,
  removeInStockStoreFacet,
  replaceCustomFacets,
  addInStockStoreFilter,
  addInStockStoreFilterOnSearch,
}
