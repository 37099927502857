import { IProduct } from '../../../PDP'

export interface IUnbxdProduct extends IProduct {
  uniqueId: string
  catlevel4Name: string
  catlevel3Name: string
  catlevel2Name: string
  catlevel1Name: string
  onSale: boolean
  attributes: string[]
  title: string
  unbxdFeedId: string
  autosuggest: string
  timeStamp_unbxd: string
  imageUrl: string[]
  longDescription: string
  [x: string]: any
  variants?: IProduct[]
}

export enum IUnbxdFacetType {
  RANGES = 'facet_ranges',
  FIELDS = 'facet_fields',
}

export interface IUnbxdFacetValue {
  counts: any[]
  gap: number
  start: number
  end: number
}

export interface IUnbxdFacet {
  type: IUnbxdFacetType
  values: IUnbxdFacetValue[]
  displayName: string
  position: number
}

export interface IUnbxdFacetResults {
  [x: string]: IUnbxdFacet
}

export interface IUnbxdFacetResultsV2 {
  text: {
    list: IUnbxdFacetTextV2[]
  }
  range: {
    list: IUnbxdFacetRangeV2[]
  }
}

export interface IUnbxdFacetRangeV2 {
  type: IUnbxdFacetType
  values: IUnbxdFacetRangeValueV2
  displayName: string
  position: number
  facetName: string
}
export interface IUnbxdFacetTextV2 {
  type: IUnbxdFacetType
  values: any []
  displayName: string
  position: number
  facetName: string
}

export interface IUnbxdFacetRangeValueV2 {
  counts: any[]
  gap: number
  start: number
  end: number
}

export interface IUnbxdProductResponse {
  numberOfProducts: number
  start: number
  products: IUnbxdProduct[]
}

export interface IUnbxdSearchQueryResponse {
  searchMetaData: {
    queryParams: any
    queryTime: number
    status: number
  }
  response: IUnbxdProductResponse
  didYouMean?: Array<{
    suggestion: string
    frequency: string
  }>
  facets: IUnbxdFacetResults
  banner?: any
}
