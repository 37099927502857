import {
  FIELD_TYPES,
  FIELD_DISPLAY_TYPES,
  IDiscount,
  IInventoryPosition,
  IShipTypeOption,
  ATTRIBUTE_TYPES,
  IProductImageType,
  IFieldGroup as IFieldGroupAlias,
  IFieldType as IFieldTypeAlias,
  IProductBreadcrumbItem as IProductBreadcrumbItemAlias,
  ICouponMetadata as ICouponMetadataAlias,
  IGenericMetadata as IGenericMetadataAlias,
  ICoupon as ICouponAlias,
  ICouponRule as ICouponRuleAlias,
  IVendor as IVendorAlias,
  IShipType as IShipTypeAlias,
  IShippingRule as IShippingRuleAlias,
  ISwatch as ISwatchAlias,
  IProduct as IProductAlias,
  IAttribute as IAttributeAlias,
  IFieldDisplayType as IFieldDisplayTypeAlias,
  IAttributeType as IAttributeTypeAlias,
  IAttributeDisplayType as IAttributeDisplayTypeAlias,
  IAttributeOption as IAttributeOptionAlias,
  IAttributeGroup as IAttributeGroupAlias,
  IFamily as IFamilyAlias,
  IProductAttributeField as IProductAttributeFieldAlias,
  IFamilyRest as IFamilyRestAlias,
  IProductImage as IProductImageAlias,
  IProductPDF as IProductPDFAlias,
  IProductGroup as IProductGroupAlias,
  ICollection as ICollectionAlias,
  ICollectionBanner as ICollectionBannerAlias,
  IPromotionsBox as IPromotionsBoxAlias,
  IPromotionsBoxRule as IPromotionsBoxRuleAlias,
} from '../Common/Interfaces/Strapi/Product'

import { ITransport } from '../Common/Services/API/CMS'
import ProductBreadcrumbs from './ProductBreadcrumbs/ProductBreadcrumbs'
import PDP from './PDP'

export { PDP, ProductBreadcrumbs }

export interface IPDPLayoutConfigurationColumn {
  span: string
  type: string
  rows: IPDPLayoutConfigurationRow[]
  parameter: string[]
  feature: string[]
  render?: () => JSX.Element
}

export enum IPDPLayoutConfigurationColumnType {
  'IMAGE_CORE' = 'IMAGE_CORE',
  'ATC' = 'ATC',
  'OTHER_ATTRIBUTES' = 'OTHER_ATTRIBUTES',
  'QTY_COUNTER' = 'QTY_COUNTER',
  'PRODUCT_GROUP' = 'PRODUCT_GROUP',
  'CUSTOM' = 'CUSTOM',
  'CORE_ATTRIBUTES' = 'CORE_ATTRIBUTES',
  'CONTAINER' = 'CONTAINER',
  'RENDER_PROPS' = 'RENDER_PROPS',
  'ATTRIBUTE_GROUP' = 'ATTRIBUTE_GROUP',
  'ATTRIBUTE' = 'ATTRIBUTE',
  'PDFS' = 'PDFS',
}

export interface IPDPLayoutConfigurationRow {
  columns: IPDPLayoutConfigurationColumn[]
}

export interface IPDPConfiguration {
  layout?: IPDPLayoutConfigurationRow[]
  renderProps: {
    [x: string]: (product: IProduct, quantity?: number) => JSX.Element
  }
  defaultTransport?: ITransport
  defaultImage?: string
  methods: {
    addItemToCart: (targetProduct: IProduct, quantity: number) => void
    navigateToProduct: (product: IProduct) => void
  }
}

export interface ICollectionConfiguration {
  methods: {
    addItemToCart: (targetProduct: IProduct, quantity: number) => void
    navigateToProduct: (product: IProduct) => void
  }
}

// ---------------------------------- ALIASES ------------------------------------

export { FIELD_TYPES }
export { FIELD_DISPLAY_TYPES }
export { IDiscount }
export { IInventoryPosition }
export { IShipTypeOption }
export { ATTRIBUTE_TYPES }
export { IProductImageType }
export type IFieldGroup = IFieldGroupAlias
export type IFieldType = IFieldTypeAlias
export type IProductBreadcrumbItem = IProductBreadcrumbItemAlias
export type ICouponMetadata = ICouponMetadataAlias
export type IGenericMetadata = IGenericMetadataAlias
export type ICoupon = ICouponAlias
export type ICouponRule = ICouponRuleAlias
export type IVendor = IVendorAlias
export type IShipType = IShipTypeAlias
export type IShippingRule = IShippingRuleAlias
export type ISwatch = ISwatchAlias
export type IProduct = IProductAlias
export type IAttribute = IAttributeAlias
export type IFieldDisplayType = IFieldDisplayTypeAlias
export type IAttributeType = IAttributeTypeAlias
export type IAttributeDisplayType = IAttributeDisplayTypeAlias
export type IAttributeOption = IAttributeOptionAlias
export type IAttributeGroup = IAttributeGroupAlias
export type IFamily = IFamilyAlias
export type IProductAttributeField = IProductAttributeFieldAlias
export type IFamilyRest = IFamilyRestAlias
export type IProductImage = IProductImageAlias
export type IProductPDF = IProductPDFAlias
export type IProductGroup = IProductGroupAlias
export type ICollection = ICollectionAlias
export type ICollectionBanner = ICollectionBannerAlias
export type IPromotionsBox = IPromotionsBoxAlias
export type IPromotionsBoxRule = IPromotionsBoxRuleAlias
