import * as React from 'react'
import css from './Layout.module.scss'

export interface IViewControllerProps {
  children: any
  xs?: boolean
  sm?: boolean
  md?: boolean
  lg?: boolean
  when?: boolean
  /**
   * Determins if the wrapper is a display level object
   * display by default: options include: block or inline-display
   */
  block?: boolean
}

/**
 * Bootstrap Utility class for controlling visibility for responsive design
 */
export default class ViewController<T extends IViewControllerProps> extends React.Component<T, any> {
  // <Hidden xs sm />
  public determineValue = (value: boolean | undefined, target: string): string => {
    const reverse: string = target === 'hidden' ? css.visible : css.hidden
    return value === undefined ? reverse : target
  }
  public render() {
    return <div>{this.props.children}</div>
  }
  protected generateClasses = (target: string): string => {
    const classes = []
    const { when, block } = this.props
    const isBlock: boolean = block === undefined ? true : (block as boolean)
    const targetBlockClass: any = isBlock ? css.block : css.inline
    const showArray: string = [targetBlockClass].join(' ')
    const hideArray: string = [css.hidden].join(' ')
    if (when !== undefined) {
      if (!when) {
        return target === 'visible' ? hideArray : showArray
      } else {
        return target === 'visible' ? showArray : hideArray
      }
    }
    const { xs, sm, md, lg } = this.props
    const targetBlockString = isBlock ? 'block' : 'inline-block'
    classes.push(`${this.determineValue(xs, target)}-xs-${targetBlockString}`)
    classes.push(`${this.determineValue(sm, target)}-sm-${targetBlockString}`)
    classes.push(`${this.determineValue(md, target)}-md-${targetBlockString}`)
    classes.push(`${this.determineValue(lg, target)}-lg-${targetBlockString}`)
    return classes.join(' ')
  }
}
