import appendQueryString from '../../../Utils/appendQueryString'
import { IApiCouponResponse, IApiCouponsResponse } from '../../../../Cart'
import { ITransport } from '.'

export interface ICouponApiParams {
  code: string
}
export interface ICouponsApiParams {
  codes: string[] | string
  cacheKey?: string
}

const ENDPOINTS = {
  COUPON: `coupons`,
  COUPON_BY_CODE: `coupons/code`,
  COUPON_BY_CODES: `coupons/codes`,
}

// ----------------------------------------------------- coupons -----------------------------------------------------------------------
const getCoupon = async (cmsTransport: ITransport, params: ICouponApiParams): Promise<IApiCouponResponse> => {
  try {
    const finalEndpoint: string = appendQueryString(`/${ENDPOINTS.COUPON}`, params)
    const response = await cmsTransport.get(finalEndpoint)
    return {
      error: '',
      data: response.data[0],
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

// get coupon by code
const getCouponByCode = async (cmsTransport: ITransport, params: ICouponApiParams): Promise<IApiCouponResponse> => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.COUPON_BY_CODE}`
    const response = await cmsTransport.post(finalEndpoint, params)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

// get coupons by codes
const getCouponsByCodes = async (cmsTransport: ITransport, params: ICouponsApiParams): Promise<IApiCouponsResponse> => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.COUPON_BY_CODES}`
    const response = await cmsTransport.post(finalEndpoint, params)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  getCoupon,
  getCouponByCode,
  getCouponsByCodes,
}
