import { IShippingRule } from '../../../../PDP'
import appendQueryString from '../../../Utils/appendQueryString'
import { ITransport } from '.'

const ENDPOINTS = {
  GET: `shippingrules`,
}
export interface IShippingRulesParams {
  vendor: string
}

export interface IShippingRulesApiResponse {
  data: IShippingRule[] | null
  error: string
}

// ----------------------------------------------------- pages -----------------------------------------------------------------------
const getShippingRulesByVendor = async (
  transport: ITransport,
  params: IShippingRulesParams,
): Promise<IShippingRulesApiResponse> => {
  try {
    const finalEndpoint: string = appendQueryString(`/${ENDPOINTS.GET}`, params)
    const response = await transport.get(finalEndpoint)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  getShippingRulesByVendor,
}
