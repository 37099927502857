import { IProduct } from '../../../PDP'

const getSmallestPrice = (product: Partial<IProduct>) => {
  const priceArray: number[] = []
  const { ourPrice, salePrice, mapEnforced, map } = product
  if (mapEnforced && map) {
    const numMap: number = parseFloat(map as any)
    return numMap
  }
  // if (price) {
  //   priceArray.push(price)
  // }
  if (ourPrice) {
    priceArray.push(ourPrice)
  }
  if (salePrice) {
    priceArray.push(salePrice)
  }
  const smallestPrice: number = Math.min(...priceArray)
  return smallestPrice
}

export default getSmallestPrice
