import { ITransport } from '.'
// import appendQueryString from '../../../Utils/appendQueryString'
import { IStoreLocation } from '../../../../Locations/interfaces'
import CACHE_CONTSTANT from '../../../Utils/cache/constants'

const ENDPOINTS = {
  ALL_LOCATIONS: `locations`,
  ONE_LOCATION: `locations?legacyStoreId=`,
}

// ------------------------------------------- all locations --------------------------------------------
const getLocations = async (
  transport: ITransport,
): Promise<{
  data: IStoreLocation[] | null
  error: string
}> => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.ALL_LOCATIONS}`
    const response = await transport.get(finalEndpoint)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

// ----------------------------------------- one location --------------------------------------------
const getSingleLocation = async (transport: ITransport, storeCode: string | string[]) => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.ONE_LOCATION}${storeCode}&_limit=1`
    const response = await transport.get(finalEndpoint, {
      headers: { cacheKey: `${CACHE_CONTSTANT.FRONTEND.MISC.LOCATIONS}-store-code-${storeCode}` },
    })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occured',
      data: null,
    }
  }
}

export default {
  getSingleLocation,
  getLocations,
}
