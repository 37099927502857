import { IncomingMessage } from 'http'
import { getCookie } from '../../utils/cookie'
import { DEFAULT_STORE_CODES, ILocationCookieType } from '../StoreLocator/interfaces'
import { IStoreCodes } from '../Tenant/Services/GERS/Pricing/storelocator'

const getStoreCode = () => {
  const existingCookie: string | undefined = getCookie(ILocationCookieType.STORE_CODES, null)
  if (existingCookie) {
    const codes: IStoreCodes = JSON.parse(decodeURIComponent(existingCookie)) as IStoreCodes
    return codes.storeCode
  } else {
    return 'XX'
  }
}

const getStoreCodes = (req?: IncomingMessage) => {
  const existingCookie: string | undefined = getCookie(ILocationCookieType.STORE_CODES, req)
  let codes: IStoreCodes = DEFAULT_STORE_CODES
  if (existingCookie) {
    codes = JSON.parse(decodeURIComponent(existingCookie)) as IStoreCodes
  }
  return codes
}

export { getStoreCode, getStoreCodes }
