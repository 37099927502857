import { ITransport } from '.'
import appendQueryString from '../../../Utils/appendQueryString'
import { ICategory } from '../../../../Catalog'
import { IProduct } from '../../../../PDP'
import CACHE_CONTSTANT from '../../../Utils/cache/constants'

const ENDPOINTS = {
  CATALOG_PRODUCTS: `categories/fetchProducts`,
  CATEGORY_TREE: `categorytrees`,
  BREADCRUMBS: `categories/breadcrumbs`,
}
export interface ICategoryProducts {
  start?: number
  limit?: number
  categoryQuery: { [x: string]: any }
  matchingFields: { [x: string]: any }
  hideProducts?: boolean
  showFields?: boolean
  cacheKey?: string
}

export interface IGenericApiResponseResult {
  data: IAssociatedOptions | null
  error?: string
}

export interface ICategoryTreeParams {
  title: string
  cacheKey: string
}

export interface ICategoryBreadcrumb {
  title: string
  slug: string
  id: string
}

export interface IAssociatedOptions {
  products: IProduct[]
  subcategories: any[]
  breadcrumbs: ICategoryBreadcrumb[]
  options: Array<{
    id: string
    code: string
    label: string
    values: Array<{
      value: string
      count: number
    }>
  }>
}

// ----------------------------------------------------- pages -----------------------------------------------------------------------
const getProductsAssociatedWithCategory = async (
  transport: ITransport,
  params: ICategoryProducts,
): Promise<IGenericApiResponseResult> => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.CATALOG_PRODUCTS}`
    const response = await transport.post(finalEndpoint, params)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const getCategoryTree = async (transport: ITransport, params: ICategoryTreeParams) => {
  try {
    const finalEndpoint: string = appendQueryString(`/${ENDPOINTS.CATEGORY_TREE}`, { ...params, nest: true })
    const response = await transport.get(finalEndpoint, params)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const getCategoryTreeAsNavigation = async (transport: ITransport, params: ICategoryTreeParams) => {
  try {
    const finalEndpoint: string = appendQueryString(`/${ENDPOINTS.CATEGORY_TREE}/navigation`, { ...params })
    const response = await transport.get(finalEndpoint, params)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const getBreadcrumbs = async (transport: ITransport, categoryId: string) => {
  try {
    const response = await transport.get(`/${ENDPOINTS.BREADCRUMBS}/${categoryId}`)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const getSubCategories = async (transport: ITransport, categoryId: string) => {
  const query: string = `
  {
   categories (where: {parent: "${categoryId}"}) {
     id
     _id
     title
     thumbnail
     slug
     thumbnail
   }
  }
 `
  try {
    const response: any = await transport.post('/graphql', {
      query,
      cacheKey: `${CACHE_CONTSTANT.FRONTEND.MISC.SUB_CATEGORIES_FOR}-${categoryId}`,
    })
    const subcategories: ICategory[] = response.data.data.categories
    return { data: subcategories }
  } catch (e) {
    return {
      error: 'Unable to get sub categories...',
    }
  }
}

const getCategorySummary = async (transport: ITransport, categorySlug: string, cacheKey: string) => {
  try {
    const response: any = await transport.get(`/categories/summary/${categorySlug}`, { headers: { cacheKey } })
    return {
      data: response.data,
      error: '',
    }
  } catch (e) {
    return {
      error: e.toString(),
      data: null,
    }
  }
}

export default {
  getBreadcrumbs,
  getCategorySummary,
  getCategoryTree,
  getCategoryTreeAsNavigation,
  getSubCategories,
  getProductsAssociatedWithCategory,
}
