import { IProduct } from '../../PDP'
import stringToSlug from './slugify'
import { IDynamicProduct } from '../../OmniPage/OmniFactory/DynamicAdWidget/DynamicAdWidget'
const generateProductLink = (product: IProduct | IDynamicProduct, prefix?: string) => {
  if(product) {
    const { sku, modelnumber, name } = product
    
    const modalTag: string = modelnumber !== undefined ? `-${modelnumber}` : ''
    const nameTag: string = name !== undefined ? `-${stringToSlug(name)}` : ''
    return `/${prefix || 'pdp'}/${sku}${modalTag}${nameTag}`
  }

  return ''
}

export default generateProductLink
