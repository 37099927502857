import appendQueryString from '../../../Utils/appendQueryString'
import { ITransport } from '.'
import { IVendor } from '../../../../PDP'

const ENDPOINTS = {
  GET: `vendors`,
}
export interface IVendorRequestParams {
  _id: string
}

export interface IVendorAPIResponse {
  data: IVendor[] | null
  error: string
}

// ----------------------------------------------------- pages -----------------------------------------------------------------------
const getVendors = async (transport: ITransport, params: IVendorRequestParams): Promise<IVendorAPIResponse> => {
  try {
    const finalEndpoint: string = appendQueryString(`/${ENDPOINTS.GET}`, params)
    const response = await transport.get(finalEndpoint)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  getVendors,
}
