import { ISite } from '../../../../Account'
import { ITransport } from '.'

export interface IApiSiteTesponse {
  error: string
  data: ISite[] | null
}

const ENDPOINTS = {
  SITES: `sites`,
}

// ----------------------------------------------------- coupons -----------------------------------------------------------------------
const findSiteBySiteId = async (transport: ITransport, siteId?: string): Promise<IApiSiteTesponse> => {
  try {
    const response = await transport.get(`/${ENDPOINTS.SITES}?siteId=${siteId}`)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const baseSiteQuery: string = `
    id
    _id
    title
    siteId
    navigationtrees {
      id
      title
      tag
    }
    theme {
      id
      _id
    }
`

const findSiteByDomain = async (transport: ITransport, domain: string) => {
  try {
    // first try by domain
    let query: string = `
    {
      sites (limit: 1, where: {domains_contains: "${domain}"})
      {
        ${baseSiteQuery}
      }
    }
    `
    let response = await transport.post('/graphql', { query })
    if (response.data?.data?.sites.length > 0) {
      return response.data.data.sites[0]
    }

    // second try by tag, if one is tagged default, pass it back
    query = `
    {
      sites (limit: 1, where: {tags_contains: "default"})
      {
        ${baseSiteQuery}
      }
    }
  `
    response = await transport.post('/graphql', { query })
    if (response.data?.data?.sites.length > 0) {
      return response.data.data.sites[0]
    }

    query = `
      {
        sites (limit: 1)
        {
          ${baseSiteQuery}
        }
      }
    `
    if (response.data?.data?.sites.length > 0) {
      return response.data.data.sites[0]
    }
    return null
  } catch (e) {
    console.log(e.toString())
  }

  return null
}

export default {
  findSiteBySiteId,
  findSiteByDomain,
}
