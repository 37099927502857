import * as React from 'react'

export default class OrderSummary extends React.Component<any, any> {
  public MAXSCREENSIZE = 992
  public state = {
    position: '',
    top: '0px',
    width: '380px',
  }

  public componentDidMount() {
    const screenSize = this.getScreenSize()
    if (screenSize > this.MAXSCREENSIZE) {
      const footerElement: HTMLDivElement | null = document.querySelector('.footer')
      if (footerElement) {
        const stickyDiv: HTMLDivElement | null = document.querySelector('.makeStick')
        const headerMenu: HTMLDivElement | null = document.querySelector('.header')
        if (headerMenu) {
          if (stickyDiv) {
            const body = document.body
            const html = document.documentElement
            const MaxPageheight = Math.max(
              body.scrollHeight,
              body.offsetHeight,
              html.clientHeight,
              html.scrollHeight,
              html.offsetHeight,
            )
            const windowScrolled = window.scrollY
            const footerHeight = footerElement.clientHeight
            const headerHeight = headerMenu.clientHeight
            const stickyContainerSize = stickyDiv.clientHeight
            const stopSticky = windowScrolled + stickyContainerSize
            const stopScroll = MaxPageheight - stopSticky

            if (windowScrolled >= headerHeight + 40) {
              if (stopScroll <= footerHeight) {
                this.handleElementPauseState(stopScroll - footerHeight - 10)
              } else {
                this.handleElementPositionState('belowHeader')
              }
            }
            if (windowScrolled <= headerHeight) {
              this.handleElementPositionState('aboveHeader')
            }
          }
        }
      }
    } else {
      this.setState({
        position: 'static',
        width: 'auto',
      })
    }
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
  }
  public componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  }
  public handleElementPositionState(pagePosition: string) {
    if (pagePosition === 'aboveHeader') {
      this.setState({
        position: 'static',
        top: '0px',
        width: '380px',
      })
    }
    if (pagePosition === 'belowHeader') {
      this.setState({
        position: 'fixed',
        top: '10px',
        width: '380px',
      })
    }
  }
  public handleElementPauseState(footerHeight: number) {
    this.setState({
      position: 'fixed',
      width: '380px',
      top: footerHeight,
    })
  }

  public handleScroll = () => {
    const screenSize = this.getScreenSize()
    if (screenSize > this.MAXSCREENSIZE) {
      const footerElement: HTMLDivElement | null = document.querySelector('.footer')
      if (footerElement) {
        const stickyDiv: HTMLDivElement | null = document.querySelector('.makeStick')
        const parentStickyDiv = stickyDiv?.parentNode as HTMLElement
        const parentStickyDivStyle = parentStickyDiv.getAttribute('style') || null
        const isParentFixedDiv = parentStickyDivStyle && parentStickyDivStyle.indexOf('fixed') > -1
        const headerMenu: HTMLDivElement | null = document.querySelector('.header')
        if (headerMenu) {
          if (stickyDiv) {
            const body = document.body
            const html = document.documentElement
            const MaxPageheight = Math.max(
              body.scrollHeight,
              body.offsetHeight,
              html.clientHeight,
              html.scrollHeight,
              html.offsetHeight,
            )
            const windowScrolled = window.scrollY
            const footerHeight = footerElement.clientHeight
            const headerHeight = headerMenu.clientHeight
            const stickyContainerSize = isParentFixedDiv ? parentStickyDiv.clientHeight : stickyDiv.clientHeight // sticky container size is currently based on child div (not the one who have fixed or static position), this affect those elements inside parent for example button in this case
            const stopSticky = windowScrolled + stickyContainerSize
            const stopScroll = MaxPageheight - stopSticky
            if (windowScrolled >= headerHeight + 40) {
              if (stopScroll <= footerHeight) {
                this.handleElementPauseState(stopScroll - footerHeight - 10)
              } else {
                this.handleElementPositionState('belowHeader')
              }
            }
            if (windowScrolled <= headerHeight) {
              this.handleElementPositionState('aboveHeader')
            }
          }
        }
      }
    }
  }
  public getScreenSize = () => {
    const w = window
    const d = document
    const e = d.documentElement
    const g = d.getElementsByTagName('body')[0]
    const x = w.innerWidth || e.clientWidth || g.clientWidth
    return x
  }
  public handleResize = () => {
    const x = this.getScreenSize()
    if (x < this.MAXSCREENSIZE) {
      this.handleScroll()
      this.setState({
        position: '',
        top: '0px',
        width: 'auto',
        bottom: '',
      })
    }
    if (x > this.MAXSCREENSIZE) {
      this.setState({
        position: 'static',
        top: '0px',
        width: '380px',
        bottom: '',
      })
      this.handleScroll()
    }
  }

  public render() {
    const styles = {
      position: this.state.position,
      top: this.state.top,
      width: this.state.width,
    } as React.CSSProperties
    return (
      <React.Fragment>
        <div style={styles}>{this.props.children}</div>
      </React.Fragment>
    )
  }
}
