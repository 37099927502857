import { INestedNavigationTreeItem } from '../../OmniPage/interfaces'

interface IFlattenedNavigationItem {
  parent: {
    id: string
  }
  [x: string]: any
}

const sortBy = (array: INestedNavigationTreeItem[]): INestedNavigationTreeItem[] => {
  return array.sort((a: INestedNavigationTreeItem, b: INestedNavigationTreeItem) => {
    return a.treeIndex < b.treeIndex ? -1 : 1
  })
}

const getNestedTree = (arr: INestedNavigationTreeItem[], parentId: any): INestedNavigationTreeItem[] => {
  const out: INestedNavigationTreeItem[] = []
  for (const i in arr) {
    if (arr[i]) {
      if (arr[i].parent === parentId) {
        const children = getNestedTree(arr, arr[i].id)
        if (children.length) {
          arr[i].children = sortBy(children)
        }
        out.push(arr[i])
      }
    }
  }
  return out
}

const nestThisTree = (array: IFlattenedNavigationItem[], parentId: any): INestedNavigationTreeItem[] => {
  const newArray: any[] = []
  for (const something of array) {
    newArray.push({
      ...something,
      parent: something.parent ? something.parent.id : null,
    })
  }
  return sortBy(getNestedTree(newArray, parentId))
}

export default nestThisTree
