/* eslint-disable @typescript-eslint/camelcase */
import { IProduct } from '../../../PDP'
import getSmallestPrice from '../../Utils/cart/getSmallestPrice'

interface IUnbxd {
  track: (type: string, obj: { [x: string]: any }) => void
}

const debug: boolean = false
const log = (message: string) => {
  if (debug) {
    // tslint:disable-next-line:no-console
    console.log(`UNBXD: ${message}`)
  }
}

const getUnbxd = (): IUnbxd | null => {
  if (window) {
    const win: any = window as any
    const Unbxd = win.Unbxd
    return retryToFindUnbxd(Unbxd, 0)
  } else {
    log('Warning: No Window Present')
  }
  return null
}

const retryToFindUnbxd = (Unbxd: IUnbxd, counter: number): IUnbxd | null => {
  if (Unbxd) {
    return Unbxd
  } else {
    if (counter > 4) {
      log('Warning: Unbxd not present on window')
      return null
    }
    setTimeout(() => {
      retryToFindUnbxd(Unbxd, ++counter)
    }, 100)
    return null
  }
}

// --------------------------------------------------------------------- events --------------------------------------------------------------------------------------------

const onUnbxdSearch = (search: string) => {
  const Unbxd: IUnbxd | null = getUnbxd()
  if (Unbxd && Unbxd.track) {
    log(`search: ${search}`)
    Unbxd.track('search', {
      query: search,
    })
  }
}

const onUnbxdSearchFacets = (query: string, facets: { [x: string]: any }) => {
  const Unbxd: IUnbxd | null = getUnbxd()
  if (Unbxd && Unbxd.track) {
    Unbxd.track('facets', {
      query,
      facets,
    })
    log(`search:facets: ${query}`)
  }
}

const onUnbxdCategoryFacets = (page: string, pageType: string, facets: { [x: string]: any }) => {
  const Unbxd: IUnbxd | null = getUnbxd()
  if (Unbxd && Unbxd.track) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    Unbxd.track('facets', {
      page,
      page_type: pageType,
      facets,
    })
    log(`category:facets: ${page}:${pageType}:${JSON.stringify(facets)}`)
  }
}

const onUnbxdSearchImpression = (query: string, skus: string[]) => {
  const Unbxd: IUnbxd | null = getUnbxd()
  if (Unbxd && Unbxd.track) {
    Unbxd.track('search_impression', {
      query: query,
      pids_list: skus,
    })
    log(`search:impression: ${query}:${skus.length}}`)
  }
}

const onUnbxdRecommendation = (boxType: string, query: string | string[], skus: string[]) => {
  const Unbxd: IUnbxd | null = getUnbxd()
  if (Unbxd && Unbxd.track) {
    Unbxd.track('widgetImpression', {
      boxType: boxType,
      identifier: query,
      pids_list: skus,
    })
    log(`widgetImpression: ${boxType}:${query}:${skus.length}}`)
  }
}

const onUnbxdBrowseImpression = (query: string, pageType: string, skus: string[]) => {
  const Unbxd: IUnbxd | null = getUnbxd()
  if (Unbxd && Unbxd.track) {
    Unbxd.track('browse_impression', {
      query: query,
      pageType,
      pids_list: skus,
    })
    log(`browse_impression: ${query}:${skus.length}}`)
  }
}

const onUnbxdProductClick = (sku: string, prank: string, requestId: string) => {
  const Unbxd: IUnbxd | null = getUnbxd()
  if (Unbxd && Unbxd.track) {
    log(`click: ${sku}:${prank}:${requestId}`)
    Unbxd.track('click', {
      pid: sku,
      prank: prank,
      requestId: requestId,
    })
  }
}

const onUnbxdAddToCart = (sku: string, qty: string, requestId: string) => {
  const Unbxd: IUnbxd | null = getUnbxd()
  if (Unbxd && Unbxd.track) {
    Unbxd.track('addToCart', {
      pid: sku,
      qty: qty,
      requestId: requestId,
    })
    log(`addToCart: ${sku}:${qty}:${requestId}`)
  }
}

const onUnbxdRemoveFromCart = (sku: string, qty: string) => {
  const Unbxd: IUnbxd | null = getUnbxd()
  if (Unbxd && Unbxd.track) {
    Unbxd.track('cartRemoval', {
      pid: sku,
      qty: qty,
    })
    log(`cartRemoval: ${sku}:${qty}`)
  }
}

const onUnbxdOrder = (
  items: Array<{
    product: IProduct
    quantity: number
  }>,
) => {
  const Unbxd: IUnbxd | null = getUnbxd()
  if (Unbxd && Unbxd.track) {
    for (const item of items) {
      const sku: string = item.product.sku
      const quantity: string = item.quantity.toString()
      const price: string = getSmallestPrice(item.product).toString()
      Unbxd.track('order', {
        pid: sku,
        qty: quantity,
        price: price,
      })
      log(`order: ${sku}:${quantity}:${price}`)
    }
  }
}

// ------------------------------------------------------------------ scripts ----------------------------------------------------------------------------
const generateUnbxdScript = (UNBXD_SITE_ID: string) => {
  const unbxdInlineScript = `
      /* * * CONFIGURATION * * */
      var UnbxdSiteName = "${UNBXD_SITE_ID}";
      /* * * DON'T EDIT BELOW THIS LINE * * */
      (function() {
        var ubx = document.createElement('script'); ubx.type = 'text/javascript'; ubx.async = true;
        ubx.src = '//d21gpk1vhmjuf5.cloudfront.net/unbxdAnalytics.js';
        (document.getElementsByTagName('head')[0] ||
        document.getElementsByTagName('body')[0]).appendChild(ubx);
      })();
    `
  return unbxdInlineScript
}

export {
  generateUnbxdScript,
  onUnbxdOrder,
  onUnbxdCategoryFacets,
  onUnbxdSearchFacets,
  onUnbxdRemoveFromCart,
  onUnbxdBrowseImpression,
  onUnbxdSearch,
  onUnbxdSearchImpression,
  onUnbxdRecommendation,
  onUnbxdProductClick,
  onUnbxdAddToCart,
}
