import { IPage, IPageContainer } from './Page'
import { IAddress } from './Account'
import { ISite } from './Site'
import { ITranslationMap } from './Translations'

export enum FIELD_TYPES {
  TEXTAREA = 'textarea',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  SINGLE_SELECT = 'simpleselect',
  TEXT = 'text',
}

export enum FIELD_DISPLAY_TYPES {
  DROPDOWN = 'dropdown',
  THUMBNAILS = 'thumbnails',
  BOXES = 'boxes',
  STATIC = 'static',
  SWATCHES = 'swatches',
}

export interface IFieldGroup {
  label: string
  code: string
  sort_order: number
  _id: string
  id: string
}

export interface IFieldType {
  _id: string
  id: string
  code: string
}

export interface IProductBreadcrumbItem {
  title: string
  url: string
}

export interface ICouponMetadata {
  id: string
  key: string
  value: any
}

export interface IGenericMetadata {
  id: string
  key: string
  value: any
}

export interface ICoupon {
  id: string
  _id: string
  code: string
  name?: string
  startDate: Date
  endDate: Date
  oneUseOnly?: boolean
  autoApply?: boolean
  usesCount?: number
  rules: ICouponRule[]
  metadata?: ICouponMetadata[]
  sites?: ISite[]
  skuInclusions?: string[]
  skuExclusions?: string[]
  categoryInclusions?: string[]
  categoryExclusions?: string[]
}

export interface IPromotionsBox {
  id: string
  _id: string
  rules: IPromotionsBoxRule[]
}
export interface IPromotionsBoxRule {
  id: string
  _id: string
  message: string
  coupon: string
  showAlways: boolean
  keywordsInclusions?: string
  keywordsExclusions?: string
}

export interface ICouponRule {
  id: string
  _id: string
  coupon: ICoupon
  discountType: string
  discount: number
  minSpend: number
  inventoryPosition: IDiscount
  applyPerProducts?: boolean
  sumQty?: boolean
  skuInclusions?: string[]
  skuExclusions?: string[]
  categoryInclusions?: string[]
  categoryExclusions?: string[]
}

export enum IDiscount {
  AMOUNT = 'Amount Off',
  PERCENT = 'Percent Off',
}

// --------------------------------------------------- vendor --------------------------

export interface IVendor {
  id: string
  _id: string
  name: string
  tagline: string
  shippingrules: IShippingRule[]
  outOfStockThreshold: number
  address: IAddress
}

export enum IInventoryPosition {
  IN_STOCK = 'In Stock',
  OUT_OF_STOCK = 'Out of Stock',
}

export enum IShipTypeOption {
  STANDARD = 'Standard',
  FREIGHT = 'Freight',
}

export interface IShipType {
  id: string
  _id: string
  name: IShipTypeOption
}

export interface IShippingRule {
  id: string
  _id: string
  vendor: IVendor
  processDays: number
  bufferDays: number
  minShipDays: number
  maxShipDays: number
  calculation: string
  shiptype: IShipType
  inventoryPosition: IInventoryPosition
}

export interface ISwatch {
  label: string
  code: string
  thumbnail: string
}

export interface IProduct {
  swatches?: ISwatch[]
  id: string
  _id: string
  sku: string
  description: any
  name: string
  thumbnail: string
  fieldsgroup: string
  priceUnit: string
  price: number
  ourPrice: number
  salePrice: number
  finalPrice?: number
  map: number
  mapEnforced: boolean
  microPrice: number
  microPriceUnit: string
  priceUnitDivider?: boolean
  createdAt: string
  updatedAt: string
  images: {
    items: IProductImage[]
  }
  enabled: boolean
  family: IFamily
  fields: IProductAttributeField[]
  categories: ICategory[]
  brand: string
  vendor: IVendor
  inventory: number
  productgroup?: IProductGroup
  shiptypes: IShipType[]
  modelnumber: string
  metatitle: string
  metadescription: string
  fullDescription?: {
    html: string
    css: string
  }
  collections?: ICollection[]
  prank?: string
  pdfs?: {
    items: IProductPDF[]
  }
  translationmap?: ITranslationMap
  default_variant?: string
  dynamicFields?: any
  robots?: string
}

export interface IAttribute {
  _id: string
  id: string
  title: string
  code: string
  label: string
  type: IAttributeType
  group: IAttributeGroup
  options: IAttributeOption[]
  displayType: IFieldDisplayType
  sort_order: number
  unique: boolean
  translationmap?: ITranslationMap
}

export interface IFieldDisplayType {
  _id: string
  id: string
  code: FIELD_DISPLAY_TYPES
}

export interface IAttributeType {
  code: ATTRIBUTE_TYPES
  id: string
}

export interface IAttributeDisplayType {
  code: ATTRIBUTE_TYPES
  id: string
}

export interface IAttributeOption {
  code: string
  attributes: IAttribute[]
  sort_order: number
  label: any
  id: string
  thumbnail: string
  translationmap?: ITranslationMap
}

export interface IAttributeGroup {
  code: string
  sort_order: number
  label: string
  id: string
  translationmap?: ITranslationMap
}

export enum ATTRIBUTE_TYPES {
  TEXT = 'text',
  TEXT_AREA = 'textarea',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  SIMPLE_SELECT = 'simpleselect',
  MULTI_SELECT = 'multiselect',
  PRICE = 'price_collection',
  METRIC = 'metric',
  DATE = 'date',
  IMAGE = 'image',
  INPUT = 'input',
  FILE = 'file',
}

export interface IFamily {
  id: string
  _id: string
  label: string
  code: string
  attributes: IAttribute[]
  translationmap?: ITranslationMap
  [x: string]: any
}

export interface IProductAttributeField {
  fieldValue: any
  fieldLabel: string
  fieldCode: string
  attribute: IAttribute
  id: string
  _id?: string
  product?: IProduct
}

export interface IFamilyRest {
  label: string
  code: string
  _id: string
  id: string
  attributes: string[]
  translationmap?: ITranslationMap
}

export enum IProductImageType {
  IMAGE = 'image',
  VIDEO = 'video',
}
export interface IProductImage {
  id: string
  url: string
  alt: string
  type: IProductImageType
  thumbnail: string
  sequenceNumber?: number
}

export interface IProductPDF {
  id: string
  sku?: string
  url: string
  type: string
  sequenceNumber?: number
}

export interface IProductGroup {
  title: string
  id: string
  _id: string
  products: IProduct[]
  leadAttribute: IAttribute
  attributes: IAttribute[]
  searchGroupAttribute: IAttribute
  categories?: ICategory[]
  tags: string
  metadata: IGenericMetadata
  collections?: ICollection[]
  translationmap?: ITranslationMap
}

export interface ICollection {
  title: string
  route: string
  id: string
  _id: string
  images: {
    items: IProductImage[]
  }
  marketingCopy: string
  banner: ICollectionBanner
  products: IProduct[]
  productgroups?: IProductGroup[]
  translationmap?: ITranslationMap
}

export interface ICollectionBanner {
  id: string
  text?: {
    html: string
    css: string
  }
  url: string
  alt: string
  buttonText?: string
  bannerUrl: string
}

export interface IShippingCostRule {
  id: string
  _id: string
  weight: number
  operator: string
  threshold: number
  shippingcost: number
  category: ICategory
}
export interface IFlyoutImage {
  id: string
  _id: string
  flyoutImageUrl: string
  flyoutImageLink: string
}

export interface ICategory {
  id: string
  _id?: string
  title: string
  children: ICategory[]
  categorytree: ICategoryTree | string
  pages: IPage[]
  parent: ICategory
  heroBanner: string
  thumbnail: string
  heroText: { html?: string }
  treeIndex: number
  slug: string
  metaTitle: string
  metaDescription: string
  postCount?: number
  keywords: string
  shippingcostrules: IShippingCostRule
  shippingMessage: string
  useShippingRules: boolean
  template: IPage
  templateEnabled: boolean
  products?: IProduct[]
  flyoutImage?: IFlyoutImage[]
  depth?: number
  productgroups?: IProductGroup[]
  hidden?: boolean
  translationmap?: ITranslationMap
  robots?: string
}

export interface ICategoryTree {
  _id: string
  id: string
  title: string
  categories: ICategory[]
}

export interface IPDPLayout {
  id: string
  _id: string
  title: string
  layout: any
  enabled: boolean
  pagecontainer: IPageContainer
}
