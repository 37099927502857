import nestThisTree from '../../../Utils/nestThisTree'
import { INestedNavigationTreeItem } from '../../../../OmniPage/interfaces'
import { ITransport } from '.'

export interface INavigationTreeParams {
  title: string
  nested: boolean
}

// ----------------------------------------------------- pages -----------------------------------------------------------------------
const getNavigationTreeStructure = async (transport: ITransport, params: INavigationTreeParams) => {
  try {
    const query: string = `
      {
        navigationtrees (where:{title: "${params.title}"}) {
          title
          pages {
            id
            treeIndex
            title
            route
            visibility
            parent{
              id
            }
          }
        }
      }
    `
    const response = await transport.post('/graphql', {
      query,
      cacheKey: `navigationtrees?title=${params.title}`,
    })
    const tree: any = response.data.data.navigationtrees[0]
    const root: any = {
      id: null,
      children: tree.pages,
    }
    const nestedItems: INestedNavigationTreeItem[] = nestThisTree(root.children, null)
    return {
      error: '',
      data: nestedItems,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  getNavigationTreeStructure,
}
