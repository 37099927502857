import * as React from 'react'
import css from './Layout.module.scss'

export interface IAppProps {
  children: any
  margins?: boolean
  padding?: boolean
  hidden?: string
  visible?: string
}
/**
 * Wrapper Class for keeping the use of Bootrap components consistent
 * @param margins boolean: add 15px top and bottom margin to your row
 * @param hidden string: pass in in thresholds to hide on: exampe is 'xs sm'
 * @param visible string: pass in in thresholds to show on: exampe is 'xs sm'
 */
export default class Row extends React.Component<IAppProps, any> {
  public generateClasses = () => {
    const { hidden, visible, margins, padding } = this.props
    let classes = [css.row]
    if (hidden !== undefined) {
      const split: string[] = hidden.split(' ')
      for (let i: number = 0; i < split.length; i++) {
        split[i] = css[`hidden-${split[i]}`]
      }
      classes = [...classes, ...split]
    }
    if (visible !== undefined) {
      const split: string[] = visible.split(' ')
      for (let i: number = 0; i < split.length; i++) {
        split[i] = css[`visible-${split[i]}`]
      }
      classes = [...classes, ...split]
    }
    if (margins) {
      classes.push(css.margin)
    }
    if (padding) {
      classes.push(css.padding)
    }
    // for visibility purposes
    classes.push('merce-row')
    return classes.join(' ')
  }
  public render() {
    return <div className={this.generateClasses()}>{this.props.children}</div>
  }
}
