import * as React from 'react'
import { find } from '../../../Common/Utils/lodash'
import css from './Sort.scss'

type SelectChangeEvent = React.ChangeEvent<HTMLSelectElement>

export enum IProductSortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export interface IProductsSortOption {
  direction?: IProductSortDirection
  label: string
  value: string
}
export interface IProps {
  productSortConfiguration?: {
    options: IProductsSortOption[]
    active: string
  }
  query: any
  shownItems: number
  maxItems: number
  onChange: (option: IProductsSortOption) => void,
  searchMetaData?: any
}

export default class Filters extends React.Component<IProps, any> {
  public renderFilterOptions = () => {
    const { productSortConfiguration } = this.props
    if (!productSortConfiguration) {
      return <option />
    }
    return productSortConfiguration.options.map((filter: IProductsSortOption, index: number) => {
      return (
        <option key={index} value={filter.value}>
          {filter.label}
        </option>
      )
    })
  }

  public getActiveSortOptionFromMetadata = (metadata: any): any => {
    if (!metadata.queryParams.sort) {
      return 'default'
    }
    if (metadata.queryParams.sort.indexOf('finalPrice') !== -1) {
      if (metadata.queryParams.sort.indexOf('asc') !== -1) {
        return 'price_lowest'
      } else {
        return 'price_highest'
      }
    }
    return metadata.queryParams.sort.replace(/asc|desc/g, '').trim()
  }

  public onSortChange = (evt: SelectChangeEvent) => {
    const { productSortConfiguration } = this.props
    if (productSortConfiguration) {
      const targetOption: IProductsSortOption | undefined = find(productSortConfiguration.options, {
        value: evt.target.value,
      })
      if (targetOption) {
        this.props.onChange(targetOption)
      }
    }
  }

  public renderFiltersRowContainer = () => {
    const { shownItems, maxItems, productSortConfiguration, searchMetaData } = this.props
    return (
      <div className={css.filterRow}>
        <div className={css.shownResults}>
          Showing 1-
          {shownItems > maxItems ? maxItems : shownItems} of {maxItems}
        </div>
        <div className={css.flex} style={{ textAlign: 'right' }}>
          <span className={css.filterSelectLabel}>Sort by:</span>
          <span className={css.filterSelectContainer}>
            <select
              onChange={this.onSortChange}
              value={(searchMetaData && searchMetaData.queryParams && searchMetaData.queryParams.sort && searchMetaData.queryParams.sort.length > 1) ? this.getActiveSortOptionFromMetadata(searchMetaData) : (productSortConfiguration ? productSortConfiguration.active : '')}
            >
              {this.renderFilterOptions()}
            </select>
          </span>
        </div>
      </div>
    )
  }
  public render() {
    return <React.Fragment>{this.renderFiltersRowContainer()}</React.Fragment>
  }
}
