import { ITransport } from '.'

const ENDPOINTS = {
  CREATE_NEW_ORDER: `orders`,
  FIND_ORDER: `orders`,
  UPDATE_ORDER: `orders`,
  SEND_EMAIL: 'orders/email',
}

export interface IGenericResponse {
  data: any | null
  error: string
}

const getBrowserIp = async (transport: ITransport): Promise<IGenericResponse> => {
  try {
    const response = await transport.get('/get-browser-ip')
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const getOrderCount = async (transport: ITransport): Promise<IGenericResponse> => {
  try {
    const response = await transport.get('/orders/count/')
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: e.toString(),
      data: null,
    }
  }
}

// ----------------------------------------------  blog category blog posts ---------------------------------------------------
const createNewOrder = async (transport: ITransport, params: any): Promise<IGenericResponse> => {
  try {
    const response = await transport.post(`/${ENDPOINTS.CREATE_NEW_ORDER}`, params)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: e?.toString() || 'An error has occurred...',
      data: null,
    }
  }
}

const findOrderByTransaction = async (transport: ITransport, targetId: string): Promise<IGenericResponse> => {
  try {
    let response = await transport.get(`/${ENDPOINTS.FIND_ORDER}/orderId/${targetId}`)

    if (!response.data._id) {
      response = await transport.get(`/${ENDPOINTS.FIND_ORDER}/paypalOrderRequestId/${targetId}`)
      return {
        error: '',
        data: response.data._id ? response.data : null,
      }
    }
    return {
      error: '',
      data: response.data._id ? response.data : null,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const updateOrder = async (transport: ITransport, orderId: string, params: any): Promise<IGenericResponse> => {
  try {
    const response = await transport.put(`/${ENDPOINTS.UPDATE_ORDER}/${orderId}`, params)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}
const sendEmail = async (transport: ITransport, params: any): Promise<IGenericResponse> => {
  try {
    const response = await transport.post(`/${ENDPOINTS.SEND_EMAIL}`, params)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  createNewOrder,
  findOrderByTransaction,
  updateOrder,
  getBrowserIp,
  sendEmail,
  getOrderCount,
}
