import CACHE_CONTSTANT from '../../../Utils/cache/constants'
import { ITransport } from '.'

export interface IProductApiParams {
  sku: string
}

export interface IProductGroupParams {
  id: string
}

// const ENDPOINTS = {
//   PRODUCT: `products`,
//   PRODUCT_GROUP: `productgroups`,
//   ATTRIBUTE: `productattributes`,
// }

const getProductData = async (transport: ITransport, params: IProductApiParams) => {
  try {
    const response = await transport.post(`/products/simple/${params.sku}`, {
      populate: 'categories shiptypes vendor fields pdp',
      cacheKey: `${CACHE_CONTSTANT.FRONTEND.PIM.PRODUCTS}-${params.sku}`,
    })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: e.toString(),
      data: null,
    }
  }
}

const getProductGroup = async (transport: ITransport, params: IProductGroupParams) => {
  try {
    const query: string = `
      {
        productgroup(id:"${params.id}"){
          title
          leadAttribute {
            id
            code
            title
          }
          attributes {
            id
            code
            title
          }
        }
      }
    `
    const response = await transport.post('/graphql', {
      query,
      cacheKey: `${CACHE_CONTSTANT.FRONTEND.PIM.PRODUCTS}-pg-${params.id}`,
    })
    return {
      error: '',
      data: response.data.data.productgroup,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  getProductData,
  getProductGroup,
}
