import { VISIBILITY } from './'
import { ITransport } from '.'
import { IBlogCategorySummary } from '../../../../Blog'
import appendQueryString from '../../../Utils/appendQueryString'

export interface ICategoryBlogPostsApiParams {
  identifier: string
  visibility?: VISIBILITY
  cacheKey: string
  _limit?: number // add limit parameter to allow fetch some records
}

const ENDPOINTS = {
  CATEGORY_BLOG_POSTS_ENDPOINT: `categories/blog/posts`,
  BLOG_SUMMARY: `blog/summary`,
}

// ----------------------------------------------  blog category blog posts ---------------------------------------------------
const getCategoryBlogPosts = async (
  transport: ITransport,
  params: ICategoryBlogPostsApiParams,
): Promise<{
  data: IBlogCategorySummary | null
  error: string
}> => {
  try {
    const finalEndpoint: string = appendQueryString(`/${ENDPOINTS.CATEGORY_BLOG_POSTS_ENDPOINT}`, params)
    const response = await transport.get(finalEndpoint)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

// -------------------------------------------------- blog summary -----------------------------------------------------------------
const getBlogSummary = async (transport: ITransport, cacheKey: string) => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.BLOG_SUMMARY}`
    const response = await transport.get(finalEndpoint, {
      headers: { cacheKey },
    })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  getBlogSummary,
  getCategoryBlogPosts,
}
