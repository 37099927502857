import * as React from 'react'
import bootstrap from '../../Common/Layout/Layout.module.scss'
import { find } from '../../Common/Utils/lodash'
import { MERCE_CLASS_NAMES } from '../../interfaces'
import css from './VerticalCarousel.module.scss'
import { IProductImageType } from '..'

export interface ISliderItem {
  url: string
  alt: string
  type: IProductImageType
  thumbnail?: string
  sequenceNumber?: number
  content?: {
    html: string
    css: string
  }
}
interface IProps {
  sliderData: ISliderItem[]
  defaultImage?: string
  horizontal?: boolean
}
interface IState {
  thumbnailStartIndex: number
  mainImgIndex: number
  sliderItemArray: ISliderItem[]
  hasNavigation: boolean
}

interface IDuplicateSortItem {
  sort: number
  slides: ISliderItem[]
}

/**
 * We ran into issues where sequenceNumbers could be the same and it sorts differently on client vs server
 * So we have to account for that in this method
 * @param slides
 */
const sortSliderItems = (slides: ISliderItem[] | null | undefined): ISliderItem[] => {
  if (!slides) {
    return []
  }
  let hasSequenceNumber: boolean = true
  //not all image sets will have a sequence number since this field was just introduced
  for (const slide of slides) {
    if (slide.sequenceNumber === undefined) {
      hasSequenceNumber = false
    }
  }
  if (!hasSequenceNumber) {
    return slides
  }
  const sequenceNumberGroups: IDuplicateSortItem[] = []
  for (const slide of slides) {
    const numberExists = find(sequenceNumberGroups, (n: IDuplicateSortItem) => {
      return n.sort == slide.sequenceNumber
    })
    //determine if the sequenceNumber has been used already
    if (!numberExists) {
      sequenceNumberGroups.push({
        sort: slide.sequenceNumber as number,
        slides: [slide],
      })
    } else {
      numberExists.slides.push(slide)
    }
  }
  const finalSortedItemList = sequenceNumberGroups.sort((a: IDuplicateSortItem, b: IDuplicateSortItem) => {
    const valueA: number = a.sort ?? 0
    const valueB: number = b.sort ?? 0
    return valueA > valueB ? 1 : -1
  })
  const sortedListes: ISliderItem[] = []
  for (const item of finalSortedItemList) {
    for (const slide of item.slides) {
      sortedListes.push(slide)
    }
  }
  return sortedListes
}

class VerticalCarousel extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    const sortedItems: ISliderItem[] = sortSliderItems(this.props.sliderData || [])
    this.state = {
      thumbnailStartIndex: 0,
      mainImgIndex: 0,
      sliderItemArray: sortedItems || [],
      hasNavigation: sortedItems && sortedItems.length > 5,
    }
  }

  public setMainImg = (index: number) => () => {
    this.setState({
      mainImgIndex: index,
    })
  }
  public onClickUp = () => {
    const { sliderItemArray } = this.state
    const index = this.state.mainImgIndex === 0 ? sliderItemArray.length - 1 : this.state.mainImgIndex - 1
    let thumbnailStartIndex =
      index < this.state.thumbnailStartIndex && this.state.thumbnailStartIndex > 0
        ? this.state.thumbnailStartIndex - 1
        : this.state.thumbnailStartIndex
    thumbnailStartIndex = index === sliderItemArray.length - 1 ? sliderItemArray.length - 5 : thumbnailStartIndex
    this.setState({
      mainImgIndex: index,
      thumbnailStartIndex,
    })
  }
  public onClickDown = () => {
    const { sliderItemArray, thumbnailStartIndex } = this.state
    const index = this.state.mainImgIndex === sliderItemArray.length - 1 ? 0 : this.state.mainImgIndex + 1
    let thumbnailStartIndexLocal =
      index === thumbnailStartIndex + 5 && thumbnailStartIndex < sliderItemArray.length + 1
        ? thumbnailStartIndex + 1
        : thumbnailStartIndex
    thumbnailStartIndexLocal = index === 0 ? 0 : thumbnailStartIndexLocal
    this.setState({
      mainImgIndex: index,
      thumbnailStartIndex: thumbnailStartIndexLocal,
    })
  }

  // ------------------------------------------------------------ RENDER ---------------------------------------------------------------------------------------------
  public renderThumbnails() {
    if (this.state.sliderItemArray && this.state.sliderItemArray.length > 0) {
      return this.state.sliderItemArray.map((imgSrc: ISliderItem, index: number) => {
        const inactiveClass = index !== this.state.mainImgIndex ? css.inactiveImgContainer : ''
        const isVideo = imgSrc.type === IProductImageType.VIDEO
        const placeholder = this.props.defaultImage || 'https://via.placeholder.com/800x800'
        const defaultThumbnail =
          (isVideo && imgSrc.thumbnail && imgSrc.thumbnail.length <= 0) || !imgSrc.thumbnail
            ? placeholder
            : imgSrc.thumbnail
        const isHidden = index < this.state.thumbnailStartIndex || index >= this.state.thumbnailStartIndex + 5
        const hide = isHidden ? css.hidden : ''
        return (
          <div
            key={index}
            className={`${
              this.props.horizontal
                ? `${css.carouselImgContainer} ${css.horizontalImgContainer}`
                : css.carouselImgContainer
            } ${inactiveClass} ${hide}`}
            onClick={this.setMainImg(index)}
            role="presentation"
          >
            <div className={`${inactiveClass} ${css.thumbnailContainer}`}>
              {isVideo && <i className={`fas fa-play-circle ${css.playIcon}`} />}
              <div
                className={`${css.imageThumbnail} fa`}
                role="img"
                aria-label={imgSrc.alt}
                style={{
                  backgroundImage: `url("${isVideo ? defaultThumbnail : imgSrc.url}")`,
                }}
              />
            </div>
          </div>
        )
      })
    } else {
      return <div />
    }
  }

  public renderContentBlock = (item: ISliderItem) => {
    if (item) {
      if (item.type === IProductImageType.IMAGE) {
        return (
          <div className={`${css.mainImgBlock} ${css.fade}`}>
            {/* <div className={`${css.mainImg} ${css.fade}`} style={{
            background: `url("${item.thumbnail}") no-repeat center / contain`
          }} /> */}
            <img className={`${css.mainImg}`} src={item.url} alt={item.alt} />
          </div>
        )
      } else if (item.type === IProductImageType.VIDEO) {
        return (
          <div className={css.videoContainer}>
            <video width="100%" height="100%" controls={true} preload="metadata">
              <source src={item.url} />
              Your browser does not support the video tag.
            </video>
          </div>
        )
      }
    }
    return (
      <div className={`${css.mainImgBlock} ${css.fade}`}>
        <img
          className={`${css.mainImg}`}
          src={this.props.defaultImage || 'https://via.placeholder.com/800x400'}
          alt="No images present"
        />
      </div>
    )
  }

  public render() {
    return (
      <div className={this.props.horizontal ? css.horizontalCarousel : css.verticalCarousel}>
        <div className={this.props.horizontal ? `${css.carousel} ${css.horizontal}` : css.carousel}>
          <div className={`${bootstrap['hidden-sm']} ${bootstrap['hidden-xs']}`}>
            {this.state.hasNavigation && (
              <div role="presentation" onClick={this.onClickUp}>
                <i className={`fas fa-chevron-up ${css.carouselIcon}`} />
              </div>
            )}
            <div
              className={`${MERCE_CLASS_NAMES.PDP_PRODUCT_PHOTO_CAROUSEL} ${
                this.props.horizontal ? css.horizontalThumbnails : null
              }`}
            >
              {this.renderThumbnails()}
            </div>
            {this.state.hasNavigation && (
              <div role="presentation" onClick={this.onClickDown}>
                <i className={`fas fa-chevron-down ${css.carouselIcon}`} />
              </div>
            )}
          </div>
        </div>
        <div
          className={`${MERCE_CLASS_NAMES.PDP_PRODUCT_PHOTO_MAIN} ${css.mainImageContainer} ${
            this.props.horizontal ? css.horizontalImageContainer : ''
          }`}
        >
          {/* remove next div for div with image background */}
          <div>
            <div className={`${bootstrap['visible-sm']} ${bootstrap['visible-xs']} ${css.centerNavigation}`}>
              <div role="presentation" onClick={this.onClickUp}>
                <i className={`align-middle fas fa-chevron-left ${css.carouselIcon}`} />
              </div>
            </div>
          </div>
          {this.renderContentBlock(this.state.sliderItemArray[this.state.mainImgIndex])}
          {/* remove next div for div with image background */}
          <div>
            <div className={`${bootstrap['visible-sm']} ${bootstrap['visible-xs']} ${css.centerNavigation}`}>
              <div role="presentation" onClick={this.onClickDown}>
                <i className={`align-middle fas fa-chevron-right ${css.carouselIcon}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default VerticalCarousel
