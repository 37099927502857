export interface IGenericLayoutConfigurationRow {
  columns: IGenericLayoutConfigurationColumn[]
}

export interface IGenericLayoutConfigurationColumn {
  span: string
  type: string
  classes?: string[]
  rows: IGenericLayoutConfigurationRow[]
  parameter: string[]
  feature: string[]
  render?: () => JSX.Element
}

export enum MERCE_CLASS_NAMES {
  IMAGE_GALLERY_IMG = 'merce-image-gallery-img',
  IMAGE_GALLERY_CONTAINER = 'merce-image-gallery-container',
  BUTTON = 'merce-button',
  BUTTON_A_TAG = 'merce-button-a-tag',
  BUTTON_WRAPPER = 'merce-button-wrapper',
  CART_ORDER_SUMMARY = 'merce-cart-oder-summary',
  CART_PROMO_FIELD = 'merce-cart-promo-field',
  CART_SUBTOTAL_TITLE = 'merce-cart-subtotal-tile',
  CART_SUBTOTAL_VALUE = 'merce-cart-subtotal-value',
  CART_SHIPPING_VALUE = 'merce-cart-shipping-value',
  CART_SHIPPING_TITLE = 'merce-cart-shipping-title',
  CART_TAX_VALUE = 'merce-cart-tax-value',
  CART_TAX_TITLE = 'merce-cart-tax-title',
  CART_TOTAL_VALUE = 'merce-cart-total-value',
  CART_TOTAL_TITLE = 'merce-cart-total-title',
  CART_CHECKOUT_BUTTON = 'merce-cart-checkout-button',
  CART_REMOVE_ICON = 'merce-cart-remove-icon',
  CART_PRODUCT_SKU_PRICE = 'merce-cart-product-sku-price',
  CART_PRODUCT_SUMMARY = 'merce-cart-product-summary',
  CART_PRODUCT_DETAIL = 'merce-cart-product-detail',
  CART_SHIPPING_MESSAGING = 'merce-cart-shipping-messaging',
  CART_PRODUCT_BRAND = 'merce-cart-product-brand',
  CART_PRODUCT_MODEL = 'merce-cart-product-model',
  PDP_SLUG = 'merce-PDP-slug',
  PDP_PRODUCT_PHOTO_CAROUSEL = 'merce-PDP-product-photo-carousel',
  PDP_PRODUCT_PHOTO_MAIN = 'merce-PDP-product-photo-main',
  PDP_PRODUCT_SUMMARY = 'merce-PDP-product-summary',
  PDP_PRODUCT_DESCRIPTION = 'merce-PDP-product-description',
  PDP_ADDCART_BUTTON = 'merce-PDP-addcart-button',
  PDP_PRODUCT_NAME = 'merce-PDP-product-name',
  PDP_PRODUCT_FULL_NAME = 'merce-PDP-product-full-name',
  PDP_PRODUCT_SKU = 'merce-PDP-product-SKU',
  PDP_PRODUCT_MODEL = 'merce-PDP-produdct-model',
  PDP_PRODUCT_REGULAR_PRICE = 'merce-PDP-product-regular-price',
  PDP_PRODUCT_OUR_PRICE = 'merce-PDP-product-our-price',
  PDP_PRODUCT_SALE_PRICE = 'merce-PDP-product-sale-price',
  PDP_PRODUCT_SHIPPIPNG_MESSAGE = 'merce-PDP-product-shipping-message',
  PDP_QUANTITY_BUTTON = 'merce-PDP-quantity-button',
  PDP_PRODUCT_SPECS = 'merce-PDP-product-specs',
  PDP_PRODUCT_PDFS = 'merce-PDP-pdf',
  PDP_PRODUCT_SELLING_ATTRIBUTES = 'merce-PDP-selling-attributes',
  PDP_ADDCART_BUTTON_CONTAINER = 'merce-PDP-addcart-button-container',
  PDP_PRODUCT_PHOTO_CAROUSEL_CONTAINER = 'merce-PDP-product-photo-carousel-container',
}
