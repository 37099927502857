import { IPDPLayoutConfigurationRow, IPDPLayoutConfigurationColumnType } from '../index'
import { MERCE_CLASS_NAMES } from '../../interfaces'

const DefaultLayout = (): IPDPLayoutConfigurationRow[] => {
  return [
    {
      columns: [
        {
          span: '7',
          type: IPDPLayoutConfigurationColumnType.IMAGE_CORE,
          parameter: [''],
          feature: [''],
          rows: [],
        },
        {
          span: '5',
          type: IPDPLayoutConfigurationColumnType.CONTAINER,
          feature: [''],
          parameter: [''],
          rows: [
            {
              columns: [
                {
                  span: '12',
                  type: IPDPLayoutConfigurationColumnType.CORE_ATTRIBUTES,
                  parameter: [''],
                  feature: [''],
                  rows: [],
                },
              ],
            },
            {
              columns: [
                {
                  span: '12',
                  type: IPDPLayoutConfigurationColumnType.PRODUCT_GROUP,
                  parameter: [''],
                  feature: [''],
                  rows: [],
                },
              ],
            },
            {
              columns: [
                {
                  span: '4',
                  type: IPDPLayoutConfigurationColumnType.QTY_COUNTER,
                  parameter: [''],
                  feature: [''],
                  rows: [],
                },
                {
                  span: '8',
                  type: IPDPLayoutConfigurationColumnType.RENDER_PROPS,
                  parameter: ['renderShippingMessage'],
                  feature: [''],
                  rows: [],
                },
              ],
            },
            {
              columns: [
                {
                  span: '12',
                  type: IPDPLayoutConfigurationColumnType.ATC,
                  parameter: [''],
                  feature: [''],
                  rows: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          span: '7',
          type: IPDPLayoutConfigurationColumnType.CONTAINER,
          feature: [''],
          parameter: [''],
          rows: [
            {
              columns: [
                {
                  span: '12',
                  type: IPDPLayoutConfigurationColumnType.ATTRIBUTE,
                  parameter: ['description', 'Product Summary', MERCE_CLASS_NAMES.PDP_PRODUCT_SUMMARY],
                  feature: [''],
                  rows: [],
                },
              ],
            },
            {
              columns: [
                {
                  span: '12',
                  type: IPDPLayoutConfigurationColumnType.ATTRIBUTE,
                  parameter: ['fullDescription', 'Product Description', MERCE_CLASS_NAMES.PDP_PRODUCT_DESCRIPTION],
                  feature: [''],
                  rows: [],
                },
              ],
            },
            {
              columns: [
                {
                  span: '12',
                  type: IPDPLayoutConfigurationColumnType.PDFS,
                  parameter: [''],
                  feature: [''],
                  rows: [],
                },
              ],
            },
          ],
        },
        {
          span: '5',
          type: IPDPLayoutConfigurationColumnType.ATTRIBUTE_GROUP,
          parameter: ['all', 'Specs/Dimensions', 'inline', MERCE_CLASS_NAMES.PDP_PRODUCT_SPECS],
          feature: [''],
          rows: [],
        },
      ],
    },
  ]
}

export default DefaultLayout
