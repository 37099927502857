import { ITransport } from '.'
// TODO: Wes to refactor
// import { IImportProfile } from '../../../../../../../Studio/ProductManager/pim-interfaces'

const ENDPOINTS = {
  IMPORT: `import/file`,
}

export interface IImportFileParams {
  token: string
  profile: any
}

// ----------------------------------------------  blog category blog posts ---------------------------------------------------
const importFile = async (transport: ITransport, params: IImportFileParams): Promise<any> => {
  try {
    const finalEndpoint: string = `/${ENDPOINTS.IMPORT}`
    const response = await transport.post(finalEndpoint, { ...params })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'Error importing file...',
      data: null,
    }
  }
}

export default {
  importFile,
}
