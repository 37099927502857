import * as React from 'react'
import { Row, Col } from '../Common/Layout'
import ThemedButton from '../Common/Button/Button'
import StickyContainer from '../Common/StickyContainer/StickyContainer'
import { find } from '../Common/Utils/lodash'
import { THEME_BUTTON_TYPES } from '../Common/Theme/ThemeWrapper'
import { MERCE_CLASS_NAMES } from '../interfaces'
import css from './PDP.module.scss'
import VerticalCarousel from './VerticalCarousel/VerticalCarousel'
import defaultLayout from './Layout/DefaultLayout'
import QuantitySelector from './QuantitySelector/QuantitySelector'
import PriceDisplay from './PriceDisplay'
import {
  IProduct,
  IProductAttributeField,
  IPDPConfiguration,
  IPDPLayoutConfigurationRow,
  IPDPLayoutConfigurationColumn,
  IPDPLayoutConfigurationColumnType,
  IProductGroup,
  IAttribute,
  IAttributeOption,
  ISwatch,
} from '.'
import { FIELD_DISPLAY_TYPES } from '../Common/Interfaces/Strapi/Product'

export interface IIPDProps {
  product: IProduct
  configuration: IPDPConfiguration
  horizontal?: boolean
  usePriceDivider?: boolean
  setProductInventory?: (customerMessage: string, leadTime: string) => void
}

import { IPDPLayout as IPDPLayoutAlias } from '../Common/Interfaces/Strapi/Product'
export type IPDPLayout = IPDPLayoutAlias

interface ICustomFieldOption {
  selected: boolean
  label: string
  product: IProduct
  [x: string]: any
}

interface IState {
  groupData: IProductGroup | null | undefined
  loading: boolean
  quantity: number
}

interface IContainerWrapper {
  children: any
  isSticky: boolean
}

const ContainerWrapper = (props: IContainerWrapper) => {
  if (props.isSticky) {
    return (
      <StickyContainer pageType="product">
        <div className="makeStick">{props.children}</div>
      </StickyContainer>
    )
  } else {
    return <div>{props.children}</div>
  }
}

export default class PDP extends React.Component<IIPDProps, IState> {
  public uniqueSellingOptionsLookup: {
    [x: string]: ICustomFieldOption[]
  } = {}
  public activeOptionsLookup: Array<{
    combine: string
    value: any
    code: string
  }> = []
  public targetLayout: IPDPLayoutConfigurationRow[] = this.props.configuration.layout
    ? this.props.configuration.layout
    : defaultLayout()

  constructor(props: IIPDProps) {
    super(props)
    this.state = {
      loading: false,
      groupData: this.initializeGroupData(),
      quantity: 1,
    }
  }

  public initializeGroupData = (): IProductGroup | undefined | null => {
    const groupData: IProductGroup | undefined | null = this.props.product.productgroup
    if (groupData && groupData.products) {
      for (const product of groupData.products) {
        this.addUniqueSellingOptions(product, groupData)
      }
    } else {
      this.addUniqueSellingOptions(this.props.product)
    }
    return groupData && typeof groupData === 'string' ? null : groupData
  }

  // public initialize = async () => {
  //   // const groupData: IProductGroup | null = await this.loadGroupData()
  //   const groupData: IProductGroup | undefined = this.props.product.productgroup
  //   if (groupData) {
  //     const { products } = groupData
  //     for (const product of products) {
  //       this.addUniqueSellingOptions(product, groupData)
  //     }
  //   } else {
  //     this.addUniqueSellingOptions(this.props.product)
  //   }
  //   this.setState({ loading: false })
  //   if (groupData) {
  //     this.setState({ groupData })
  //   }
  // }

  /**
   * We want to load this client side for performance
   */
  // public loadGroupData = async () => {
  //   const { configuration } = this.props
  //   if (configuration && configuration.defaultTransport) {
  //     const { product } = this.props
  //     let groupData: IProductGroup | null = null
  //     if (product.productgroup) {
  //       let getProductGroup: boolean = false
  //       for (const field of product.fields) {
  //         if (field.attribute && field.attribute.displayType && field.attribute.displayType.code !== FIELD_DISPLAY_TYPES.STATIC) {
  //           getProductGroup = true
  //         }
  //       }
  //       if (getProductGroup && product.productgroup) {
  //         this.setState({ loading: true })
  //         const groupResponse = await CMS_API_CALLS.PRODUCT.getProductGroup(configuration.defaultTransport, { id: product.productgroup as any })
  //         groupData = { ...groupResponse.data, products: [] }
  //       }
  //     }
  //     return groupData
  //   }
  //   return null
  // }

  // --------------------------------------------------------------------- EVENTS ------------------------------------------

  public navigateToProduct = (product: IProduct) => {
    this.props.configuration.methods.navigateToProduct(product)
  }

  public onQuantityChange = (num: number): void => {
    const { quantity } = this.state
    const target: number = quantity + num
    if (target < 1) {
      return
    }
    this.setState({
      quantity: quantity + num,
    })
  }

  public onSelectChangeToProduct = (evt: any) => {
    const productId: string = evt.target.value
    const { groupData } = this.state
    if (groupData && groupData.products) {
      const targetProduct: IProduct | undefined = find(groupData.products, {
        id: productId,
      })
      if (targetProduct) {
        this.navigateToProduct(targetProduct)
      }
    }
  }

  // -------------------------------------------------------------------- UTILS -------------------------------------------

  public addUniqueSellingOptions = (product: IProduct, group?: IProductGroup) => {
    for (const field of product.fields) {
      const allowedAttributes: IAttribute[] = group && group.attributes ? group.attributes : []
      const targetAttribute: IAttribute | undefined = find(allowedAttributes, {
        code: field.attribute?.code,
      })
      if (targetAttribute) {
        if (!this.uniqueSellingOptionsLookup[field.attribute.code]) {
          this.uniqueSellingOptionsLookup[field.attribute.code] = []
        }
        const existsAttribute: ICustomFieldOption = find(this.uniqueSellingOptionsLookup[field.attribute.code], {
          label: field.fieldValue,
        })
        if (!existsAttribute) {
          this.uniqueSellingOptionsLookup[field.attribute.code].push(({
            label: field.fieldValue,
            products: [product],
          } as unknown) as ICustomFieldOption)
        } else {
          existsAttribute.products.push(product)
        }

        // the items that will get highlighted
        if (product.id === this.props.product.id) {
          this.activeOptionsLookup.push({
            code: field.attribute.code,
            combine: `${field.attribute.code}:${field.fieldValue}`,
            value: field.fieldValue,
          })
        }
      }
    }
  }

  public filterByProductAttributes = (
    options: ICustomFieldOption[],
    code: string,
  ): ICustomFieldOption[] | null | undefined => {
    const groupData: IProductGroup | undefined | null = this.props.product.productgroup
    const allowedAttributes: IAttribute[] = groupData?.attributes || []

    if (allowedAttributes.length) {
      const otherAttributesCodes = allowedAttributes
        .filter((attr: IAttribute) => {
          return attr.code !== code
        })
        .map(attr => attr.code)
      const productFields = this.props.product.fields.filter((field: IProductAttributeField) => {
        return otherAttributesCodes.includes(field.attribute.code)
      })

      return options?.filter((opt: ICustomFieldOption) => {
        const relatedProducts: IProduct[] = []

        opt.products.forEach((relatedProduct: IProduct) => {
          let fullMatch: boolean = true
          const relatedProductFields = relatedProduct.fields.filter((field: IProductAttributeField) => {
            return otherAttributesCodes.includes(field.attribute.code)
          })

          productFields.forEach((field: IProductAttributeField) => {
            const relatedField = relatedProductFields.find((otherField: IProductAttributeField) => {
              return otherField.attribute.code === field.attribute.code
            })

            if (relatedField?.fieldValue !== field.fieldValue) {
              fullMatch = false
            }
          })

          if (fullMatch) {
            relatedProducts.push(relatedProduct)
          }
        })

        if (relatedProducts.length) {
          opt.products = relatedProducts
          opt.product = relatedProducts[0]
          return true
        }
        return false
      })
    }
    return options
  }

  public getOptionsByAttribute = (code: string): ICustomFieldOption[] | null | undefined => {
    return this.filterByProductAttributes(this.uniqueSellingOptionsLookup[code], code)
  }

  public findAttributeValue = (target: string): string | number => {
    const { fields } = this.props.product
    const matchThis = ['code', 'label']
    let sendBack: string | number | undefined
    for (const field of fields) {
      const fieldCast: any = field.attribute
      for (const match of matchThis) {
        const prop: string | undefined = fieldCast[match]
        if (prop !== undefined) {
          if (prop.toLowerCase() === target.toLowerCase()) {
            sendBack = field.fieldValue
          }
        }
      }
    }
    return sendBack ? sendBack : ''
  }

  /**
   * If they remove an attribute from a family,
   * it stays on product; however is no longer available
   */
  // public isAttributeAvailable = (attribute: IAttribute) => {
  //   const { product } = this.props
  //   let isAvailable: boolean = false
  //   for (const attr of product.family.attributes) {
  //     if (attribute.id === attr.id) {
  //       isAvailable = true
  //     }
  //   }
  //   return isAvailable
  // }

  public findAttribute = (target: string): IAttribute | null => {
    const { fields } = this.props.product
    const matchThis = ['code', 'label']
    let sendBack: IAttribute | null = null
    for (const field of fields) {
      const fieldCast: any = field.attribute
      for (const match of matchThis) {
        const prop: string | undefined = fieldCast[match]
        if (prop !== undefined) {
          if (prop.toLowerCase() === target.toLowerCase()) {
            sendBack = field.attribute
          }
        }
      }
    }
    return sendBack
  }

  public findAttributesByGroup = (group: string) => {
    const targetFields: IProductAttributeField[] = []
    const { fields } = this.props.product
    for (const field of fields) {
      if (group !== 'all') {
        if (field.attribute.group.code === group) {
          if (field.attribute && field.fieldValue) {
            targetFields.push(field)
          }
        }
      } else {
        if (field.attribute && field.fieldValue) {
          const isTaken: any = find(this.activeOptionsLookup, {
            code: field.attribute.code,
          })
          if (!isTaken) {
            targetFields.push(field)
          }
        }
      }
    }
    return targetFields
  }

  public isOptionSelected = (code: string): boolean => {
    return (
      find(this.activeOptionsLookup, {
        combine: code,
      }) !== undefined
    )
  }

  // --------------------------------------------------------------- RENDER -------------------------------------------------------------------------------

  public getSwatchThumbnail = (option: ICustomFieldOption) => {
    const { swatches } = this.props.product
    let thumbnail = ''
    if (swatches) {
      const optionSwatch: ISwatch | undefined = swatches.find(
        (swatch: ISwatch) => swatch.code === option.label.toLowerCase(),
      )
      thumbnail = optionSwatch && optionSwatch.thumbnail ? optionSwatch.thumbnail : ''
    }
    return thumbnail
  }

  public renderOptions = (type: string, field: IProductAttributeField) => {
    const options: ICustomFieldOption[] | null | undefined = this.getOptionsByAttribute(field.attribute.code)
    if (!options || options.length <= 1) {
      return <div style={{ marginLeft: '10px' }}>{field.fieldValue}</div>
    }
    switch (type) {
      case FIELD_DISPLAY_TYPES.BOXES:
        return options.map((option: ICustomFieldOption, index: number) => {
          return (
            <div
              id={field?.attribute?.code}
              onClick={this.navigateToProduct.bind(this, option.product)}
              key={index}
              className={`${css.boxOptions} ${
                this.isOptionSelected(`${field.attribute.code}:${option.label}`) ? css.active : ''
              }`}
            >
              {option.label}
            </div>
          )
        })
      case FIELD_DISPLAY_TYPES.SWATCHES:
        return options.map((option: ICustomFieldOption, index: number) => {
          return (
            <div
              id={field?.attribute?.code}
              onClick={this.navigateToProduct.bind(this, option.product)}
              key={index}
              className={`${css.circleOptions} ${
                this.isOptionSelected(`${field.attribute.code}:${option.label}`) ? css.active : ''
              }`}
            >
              <img className={css.circleOptions} src={this.getSwatchThumbnail(option)} />
            </div>
          )
        })
      case FIELD_DISPLAY_TYPES.DROPDOWN:
        let selectValue: string = ''
        for (const op of options) {
          const isSelected: boolean = this.isOptionSelected(`${field.attribute.code}:${op.label}`)
          if (isSelected) {
            selectValue = op.product.id
          }
        }
        return (
          <select
            id={field?.attribute?.code}
            className={css.dropdownOptions}
            value={selectValue}
            onChange={this.onSelectChangeToProduct}
          >
            {options.map((option: ICustomFieldOption, index: number) => {
              return (
                <option
                  value={option.product.id}
                  key={index}
                  className={`${this.isOptionSelected(`${field.attribute.code}:${option.label}`) ? css.active : ''}`}
                >
                  {option.label}
                </option>
              )
            })}
          </select>
        )
      case FIELD_DISPLAY_TYPES.THUMBNAILS:
        return options.map((option: ICustomFieldOption, index: number) => {
          let thumbnail: string = option.product.thumbnail
          // todo: find a better way
          const att: IAttribute | null = this.findAttribute(field.attribute.code)
          if (att && att.options) {
            const existsArray: IAttributeOption[] = att.options.filter((op: IAttributeOption) => {
              if (op.label === option.label) {
                if (op.thumbnail) {
                  return true
                }
              }
              return false
            })
            if (existsArray.length) {
              thumbnail = existsArray[0].thumbnail
            }
          }
          return (
            <div
              id={field?.attribute?.code}
              key={index}
              onClick={this.navigateToProduct.bind(this, option.product)}
              className={`${css.thumbnailOptions} ${
                this.isOptionSelected(`${field.attribute.code}:${option.label}`) ? css.active : ''
              }`}
            >
              <div
                className={css.img}
                style={{
                  backgroundImage: `url('${thumbnail}')`,
                }}
                title={option.label}
              />
            </div>
          )
        })
      default:
        return <div id={field?.attribute?.code}>{field.fieldValue}</div>
    }
  }
  public renderFieldValue = (field: IProductAttributeField) => {
    const displayTypeCode: FIELD_DISPLAY_TYPES | undefined = field?.attribute?.displayType?.code
    if (displayTypeCode !== FIELD_DISPLAY_TYPES.STATIC && this.state.groupData) {
      return this.renderOptions(displayTypeCode, field)
    }
    return <div style={{ marginLeft: '15px' }}>{field.fieldValue}</div>
  }

  public renderField = (field: IProductAttributeField) => {
    const displayTypeCode: FIELD_DISPLAY_TYPES | undefined = field?.attribute?.displayType?.code
    if (displayTypeCode === FIELD_DISPLAY_TYPES.SWATCHES) {
      const options: ICustomFieldOption[] | null | undefined = this.getOptionsByAttribute(field.attribute.code)
      if (options && options.length > 0) {
        const selectedOption = options.find(option => this.isOptionSelected(`${field.attribute.code}:${option.label}`))
        return (
          <React.Fragment>
            <div>
              <b>{field.attribute.title}</b>: {selectedOption ? selectedOption.label : null}
            </div>
            <div className={css.fieldValueContainer}>{this.renderFieldValue(field)}</div>
          </React.Fragment>
        )
      }
    }
    return (
      <React.Fragment>
        <div>
          <label htmlFor={field?.attribute?.code}>
            <b>{field.attribute.title}</b>
          </label>
          :
        </div>
        <div className={css.fieldValueContainer}>{this.renderFieldValue(field)}</div>
      </React.Fragment>
    )
  }

  public renderAttributesByGroup = (group: string, type: string | undefined) => {
    const fields: IProductAttributeField[] = this.findAttributesByGroup(group)
    if (type && type === 'table') {
      return (
        <table className={css.table}>
          <tbody>
            {fields.map((field: IProductAttributeField, index: number) => {
              return (
                <tr key={index}>
                  <td>{field.attribute.title}</td>
                  <td>{field.fieldValue}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )
    }
    if (type && type === 'inline') {
      return fields.map((field: IProductAttributeField, index: number) => {
        return (
          <div key={index}>
            <div className={css.inlineDisplay}>
              <strong>{field.attribute.title}:</strong> {field.fieldValue}
            </div>
          </div>
        )
      })
    }
    return fields.map((field: IProductAttributeField, index: number) => {
      return <div key={index}>{this.renderField(field)}</div>
    })
  }

  public renderPdpRow = (row: IPDPLayoutConfigurationRow, index: number) => {
    return (
      <div style={{ marginBottom: '15px' }} key={index}>
        <Row>
          {row.columns.map((c: IPDPLayoutConfigurationColumn, index2: number) => {
            return this.renderPdpColumn(c, index2)
          })}
        </Row>
      </div>
    )
  }

  public renderPdpColumn = (column: IPDPLayoutConfigurationColumn, index: number) => {
    return (
      <Col sm={parseInt(column.span, 10)} key={index}>
        {this.renderPdpComponent(column)}
      </Col>
    )
  }

  public renderCoreAttributes = () => {
    const { product } = this.props
    const brand: IProductAttributeField[] = product.fields.filter((attributeFields: IProductAttributeField) => {
      const type: string | undefined = attributeFields?.attribute?.code
      if (type === 'brand') {
        return true
      }
      return false
    })
    return (
      <div className={css.coreAttributes}>
        {brand && brand.length > 0 && (
          <p className={`${css.brandAttribute} ${MERCE_CLASS_NAMES.PDP_PRODUCT_NAME}`}>{brand[0].fieldValue}</p>
        )}
        <h1 className={`${css.nameAttribute} ${MERCE_CLASS_NAMES.PDP_PRODUCT_FULL_NAME}`}>{product.name}</h1>
        <p className={css.skuAttribute}>
          <span className={MERCE_CLASS_NAMES.PDP_PRODUCT_SKU}>SKU# {product.sku} </span>
          <span className={css.spacer}>|</span>{' '}
          <span className={MERCE_CLASS_NAMES.PDP_PRODUCT_MODEL}>MODEL# {product.modelnumber.toUpperCase()}</span>
        </p>
        <div className={`${css.priceAttribute}`}>
          <PriceDisplay product={product} usePriceDivider={this.props.usePriceDivider} />
        </div>
      </div>
    )
  }

  public renderATCButton = () => {
    const { quantity } = this.state
    const { product } = this.props
    let greyButton = {}
    let type = THEME_BUTTON_TYPES.PRIMARY

    if (!product.mapEnforced && product.ourPrice === null) {
      type = THEME_BUTTON_TYPES.SECONDARY
      greyButton = {
        backgroundColor: 'grey',
        pointerEvents: 'none',
        color: 'white',
      }
    }
    return (
      <div className={`${css.atcButtonContainer} ${MERCE_CLASS_NAMES.PDP_ADDCART_BUTTON_CONTAINER}`}>
        <div>
          <ThemedButton
            fullWidth={true}
            onClick={this.props.configuration.methods.addItemToCart.bind(null, this.props.product, quantity)}
            type={type}
            style={greyButton}
            className={MERCE_CLASS_NAMES.PDP_ADDCART_BUTTON}
          >
            ADD TO CART
          </ThemedButton>
        </div>
      </div>
    )
  }

  public renderAttributesGroup = (params: string[]) => {
    const group: string = params[0]
    const groupTitle: string = params[1]
    const type: string = params[2]
    const className: string = params[3] ? params[3] : ''
    const fields: IProductAttributeField[] = this.findAttributesByGroup(group)
    if (group && fields.length) {
      return (
        <div className={`${css.attributeGroup} ${className}`}>
          {groupTitle && <p className={css.groupTitle}>{groupTitle}</p>}
          {this.renderAttributesByGroup(group, type)}
        </div>
      )
    }
    return <div />
  }

  public renderProductGroupAttributes = () => {
    const targetFields: IProductAttributeField[] = []
    const { groupData, loading } = this.state
    if (loading) {
      return <div>Loading....</div>
    }
    if (groupData && groupData.attributes) {
      const allowedAttributes: IAttribute[] = groupData.attributes
      // get all attributes we are supposed to show
      const { fields } = this.props.product
      for (const field of fields) {
        const isAllowed: IAttribute | undefined = find(allowedAttributes, {
          code: field.attribute.code,
        })
        if (isAllowed) {
          if (field.attribute) {
            targetFields.push(field)
          }
        }
      }
    }
    if (!targetFields.length) {
      return <div />
    }
    return (
      <div className={`${MERCE_CLASS_NAMES.PDP_PRODUCT_SELLING_ATTRIBUTES}`}>
        <hr />
        {targetFields.map((field: IProductAttributeField, index: number) => {
          return <div key={index}>{this.renderField(field)}</div>
        })}
        <hr />
      </div>
    )
  }

  public renderAttribute = (params: string[]) => {
    const { product } = this.props as any
    const prop: string = params[0]
    const title: string = params[1]
    const className: string = params[2] ? params[2] : ''
    const attributeContent = product[prop] && product[prop].html ? product[prop].html : product[prop]
    return (
      <div className={`${css.section} ${className}`}>
        <p
          className={css.groupTitle}
          style={{
            marginBottom: '10px',
          }}
        >
          {title}:
        </p>
        <div
          className={css.descriptionAttribute}
          dangerouslySetInnerHTML={{
            __html: attributeContent,
          }}
        />
      </div>
    )
  }

  public renderImageCore = () => {
    const { product, configuration } = this.props
    return (
      <div className={`${css.contentContainer} ${MERCE_CLASS_NAMES.PDP_PRODUCT_PHOTO_CAROUSEL_CONTAINER}`}>
        <div className={css.thumbnailCarousel}>
          <VerticalCarousel
            defaultImage={configuration ? configuration.defaultImage : 'https://via.placeholder.com/800x800'}
            sliderData={product.images.items}
            horizontal={this.props.horizontal}
          />
        </div>
      </div>
    )
  }

  public renderCustomComponent = (col: IPDPLayoutConfigurationColumn) => {
    if (col.render) {
      return col.render()
    }
    return <div />
  }

  public renderContainer = (col: IPDPLayoutConfigurationColumn) => {
    return (
      <ContainerWrapper isSticky={col.feature.indexOf('sticky') > -1}>
        {col.rows.map((row: IPDPLayoutConfigurationRow, index: number) => {
          return this.renderPdpRow(row, index)
        })}
      </ContainerWrapper>
    )
  }

  public renderQtnCounter = () => {
    const { quantity } = this.state
    return <QuantitySelector quantity={quantity} onQuantityChange={this.onQuantityChange} />
  }

  public renderPdfs = () => {
    const { product } = this.props
    return (
      <div className={MERCE_CLASS_NAMES.PDP_PRODUCT_PDFS}>
        {product.pdfs
          ? product.pdfs.items.map((item, index) => (
              <Col sm={6} key={index}>
                <a className={css.pdfAnchor} href={item.url} target="_blank">
                  <i className="fa fa-file-pdf" /> {item.type}
                </a>
              </Col>
            ))
          : null}
      </div>
    )
  }

  public renderRenderProps = (col: IPDPLayoutConfigurationColumn) => {
    const param: string = col.parameter[0] as any
    const renderProps: Record<string, any> = this.props.configuration.renderProps as any
    const method: Function = renderProps[param] as any
    const { quantity } = this.state
    try {
      return <div className={css.renderProps}>{method(this.props.product, quantity)}</div>
    } catch (e) {
      return (
        <div className={css.renderProps} style={{ color: 'red' }}>
          Warning: Method not implemented:{param}
        </div>
      )
    }
  }

  public renderPdpComponent = (col: IPDPLayoutConfigurationColumn) => {
    switch (col.type) {
      case IPDPLayoutConfigurationColumnType.IMAGE_CORE:
        return this.renderImageCore()

      case IPDPLayoutConfigurationColumnType.CORE_ATTRIBUTES:
        return this.renderCoreAttributes()

      case IPDPLayoutConfigurationColumnType.ATTRIBUTE:
        return this.renderAttribute(col.parameter)

      case IPDPLayoutConfigurationColumnType.ATTRIBUTE_GROUP:
        return this.renderAttributesGroup(col.parameter)

      case IPDPLayoutConfigurationColumnType.PRODUCT_GROUP:
        return this.renderProductGroupAttributes()

      case IPDPLayoutConfigurationColumnType.ATC:
        return this.renderATCButton()

      case IPDPLayoutConfigurationColumnType.QTY_COUNTER:
        return this.renderQtnCounter()

      case IPDPLayoutConfigurationColumnType.RENDER_PROPS:
        return this.renderRenderProps(col)

      case IPDPLayoutConfigurationColumnType.CUSTOM:
        return this.renderCustomComponent(col)

      case IPDPLayoutConfigurationColumnType.CONTAINER:
        return this.renderContainer(col)

      case IPDPLayoutConfigurationColumnType.PDFS:
        return this.renderPdfs()

      default:
        return <div />
    }
  }

  public render() {
    return (
      <React.Fragment>
        <div className={css.pdpContainer}>
          {this.targetLayout.map((row: IPDPLayoutConfigurationRow, index: number) => {
            return this.renderPdpRow(row, index)
          })}
        </div>
      </React.Fragment>
    )
  }
}
